import React from "react";
import gallery1 from "../images/gallery/1.png";
import gallery1webp from "../images/gallery/1.webp";
import gallery1avif from "../images/gallery/1.avif";
import gallery1mid from "../images/gallery/1mid.png";
import gallery1midwebp from "../images/gallery/1mid.webp";
import gallery1midavif from "../images/gallery/1mid.avif";
import gallery1big from "../images/gallery/1big.png";
import gallery1bigwebp from "../images/gallery/1big.webp";
import gallery1bigavif from "../images/gallery/1big.avif";
import gallery2 from "../images/gallery/2.png";
import gallery2webp from "../images/gallery/2.webp";
import gallery2avif from "../images/gallery/2.avif";
import gallery2mid from "../images/gallery/2mid.png";
import gallery2midwebp from "../images/gallery/2mid.webp";
import gallery2midavif from "../images/gallery/2mid.avif";
import gallery2big from "../images/gallery/2big.png";
import gallery2bigwebp from "../images/gallery/2big.webp";
import gallery2bigavif from "../images/gallery/2big.avif";
import gallery3 from "../images/gallery/3.png";
import gallery3webp from "../images/gallery/3.webp";
import gallery3avif from "../images/gallery/3.avif";
import gallery3mid from "../images/gallery/3mid.png";
import gallery3midwebp from "../images/gallery/3mid.webp";
import gallery3midavif from "../images/gallery/3mid.avif";
import gallery3big from "../images/gallery/3big.png";
import gallery3bigwebp from "../images/gallery/3big.webp";
import gallery3bigavif from "../images/gallery/3big.avif";
import gallery4 from "../images/gallery/4.png";
import gallery4webp from "../images/gallery/4.webp";
import gallery4avif from "../images/gallery/4.avif";
import gallery4mid from "../images/gallery/4mid.png";
import gallery4midwebp from "../images/gallery/4mid.webp";
import gallery4midavif from "../images/gallery/4mid.avif";
import gallery4big from "../images/gallery/4big.png";
import gallery4bigwebp from "../images/gallery/4big.webp";
import gallery4bigavif from "../images/gallery/4big.avif";
import gallery5 from "../images/gallery/5.png";
import gallery5webp from "../images/gallery/5.webp";
import gallery5avif from "../images/gallery/5.avif";
import gallery5mid from "../images/gallery/5mid.png";
import gallery5midwebp from "../images/gallery/5mid.webp";
import gallery5midavif from "../images/gallery/5mid.avif";
import gallery5big from "../images/gallery/5big.png";
import gallery5bigwebp from "../images/gallery/5big.webp";
import gallery5bigavif from "../images/gallery/5big.avif";
import gallery6 from "../images/gallery/6.png";
import gallery6webp from "../images/gallery/6.webp";
import gallery6avif from "../images/gallery/6.avif";
import gallery6mid from "../images/gallery/6mid.png";
import gallery6midwebp from "../images/gallery/6mid.webp";
import gallery6midavif from "../images/gallery/6mid.avif";
import gallery6big from "../images/gallery/6big.png";
import gallery6bigwebp from "../images/gallery/6big.webp";
import gallery6bigavif from "../images/gallery/6big.avif";
import gallery7 from "../images/gallery/7.png";
import gallery7webp from "../images/gallery/7.webp";
import gallery7avif from "../images/gallery/7.avif";
import gallery7mid from "../images/gallery/7mid.png";
import gallery7midwebp from "../images/gallery/7mid.webp";
import gallery7midavif from "../images/gallery/7mid.avif";
import gallery7big from "../images/gallery/7big.png";
import gallery7bigwebp from "../images/gallery/7big.webp";
import gallery7bigavif from "../images/gallery/7big.avif";
import gallery8 from "../images/gallery/8.png";
import gallery8webp from "../images/gallery/8.webp";
import gallery8avif from "../images/gallery/8.avif";
import gallery8mid from "../images/gallery/8mid.png";
import gallery8midwebp from "../images/gallery/8mid.webp";
import gallery8midavif from "../images/gallery/8mid.avif";
import gallery8big from "../images/gallery/8big.png";
import gallery8bigwebp from "../images/gallery/8big.webp";
import gallery8bigavif from "../images/gallery/8big.avif";
import gallery9 from "../images/gallery/9.png";
import gallery9webp from "../images/gallery/9.webp";
import gallery9avif from "../images/gallery/9.avif";
import gallery9mid from "../images/gallery/9mid.png";
import gallery9midwebp from "../images/gallery/9mid.webp";
import gallery9midavif from "../images/gallery/9mid.avif";
import gallery9big from "../images/gallery/9big.png";
import gallery9bigwebp from "../images/gallery/9big.webp";
import gallery9bigavif from "../images/gallery/9big.avif";
import gallery10 from "../images/gallery/10.png";
import gallery10webp from "../images/gallery/10.webp";
import gallery10avif from "../images/gallery/10.avif";
import gallery10mid from "../images/gallery/10mid.png";
import gallery10midwebp from "../images/gallery/10mid.webp";
import gallery10midavif from "../images/gallery/10mid.avif";
import gallery10big from "../images/gallery/10big.png";
import gallery10bigwebp from "../images/gallery/10big.webp";
import gallery10bigavif from "../images/gallery/10big.avif";
import gallery11 from "../images/gallery/11.png";
import gallery11webp from "../images/gallery/11.webp";
import gallery11avif from "../images/gallery/11.avif";
import gallery11mid from "../images/gallery/11mid.png";
import gallery11midwebp from "../images/gallery/11mid.webp";
import gallery11midavif from "../images/gallery/11mid.avif";
import gallery11big from "../images/gallery/11big.png";
import gallery11bigwebp from "../images/gallery/11big.webp";
import gallery11bigavif from "../images/gallery/11big.avif";
import gallery12 from "../images/gallery/12.png";
import gallery12webp from "../images/gallery/12.webp";
import gallery12avif from "../images/gallery/12.avif";
import gallery12mid from "../images/gallery/12mid.png";
import gallery12midwebp from "../images/gallery/12mid.webp";
import gallery12midavif from "../images/gallery/12mid.avif";
import gallery12big from "../images/gallery/12big.png";
import gallery12bigwebp from "../images/gallery/12big.webp";
import gallery12bigavif from "../images/gallery/12big.avif";
import gallery13 from "../images/gallery/13.png";
import gallery13webp from "../images/gallery/13.webp";
import gallery13avif from "../images/gallery/13.avif";
import gallery13mid from "../images/gallery/13mid.png";
import gallery13midwebp from "../images/gallery/13mid.webp";
import gallery13midavif from "../images/gallery/13mid.avif";
import gallery13big from "../images/gallery/13big.png";
import gallery13bigwebp from "../images/gallery/13big.webp";
import gallery13bigavif from "../images/gallery/13big.avif";
import gallery14 from "../images/gallery/14.png";
import gallery14webp from "../images/gallery/14.webp";
import gallery14avif from "../images/gallery/14.avif";
import gallery14mid from "../images/gallery/14mid.png";
import gallery14midwebp from "../images/gallery/14mid.webp";
import gallery14midavif from "../images/gallery/14mid.avif";
import gallery14big from "../images/gallery/14big.png";
import gallery14bigwebp from "../images/gallery/14big.webp";
import gallery14bigavif from "../images/gallery/14big.avif";
import gallery15 from "../images/gallery/15.png";
import gallery15webp from "../images/gallery/15.webp";
import gallery15avif from "../images/gallery/15.avif";
import gallery15mid from "../images/gallery/15mid.png";
import gallery15midwebp from "../images/gallery/15mid.webp";
import gallery15midavif from "../images/gallery/15mid.avif";
import gallery15big from "../images/gallery/15big.png";
import gallery15bigwebp from "../images/gallery/15big.webp";
import gallery15bigavif from "../images/gallery/15big.avif";
import gallery16 from "../images/gallery/16.png";
import gallery16webp from "../images/gallery/16.webp";
import gallery16avif from "../images/gallery/16.avif";
import gallery16mid from "../images/gallery/16mid.png";
import gallery16midwebp from "../images/gallery/16mid.webp";
import gallery16midavif from "../images/gallery/16mid.avif";
import gallery16big from "../images/gallery/16big.png";
import gallery16bigwebp from "../images/gallery/16big.webp";
import gallery16bigavif from "../images/gallery/16big.avif";
import gallery17 from "../images/gallery/17.png";
import gallery17webp from "../images/gallery/17.webp";
import gallery17avif from "../images/gallery/17.avif";
import gallery17mid from "../images/gallery/17mid.png";
import gallery17midwebp from "../images/gallery/17mid.webp";
import gallery17midavif from "../images/gallery/17mid.avif";
import gallery17big from "../images/gallery/17big.png";
import gallery17bigwebp from "../images/gallery/17big.webp";
import gallery17bigavif from "../images/gallery/17big.avif";
import gallery18 from "../images/gallery/18.png";
import gallery18webp from "../images/gallery/18.webp";
import gallery18avif from "../images/gallery/18.avif";
import gallery18mid from "../images/gallery/18mid.png";
import gallery18midwebp from "../images/gallery/18mid.webp";
import gallery18midavif from "../images/gallery/18mid.avif";
import gallery18big from "../images/gallery/18big.png";
import gallery18bigwebp from "../images/gallery/18big.webp";
import gallery18bigavif from "../images/gallery/18big.avif";
import gallery19 from "../images/gallery/19.png";
import gallery19webp from "../images/gallery/19.webp";
import gallery19avif from "../images/gallery/19.avif";
import gallery19mid from "../images/gallery/19mid.png";
import gallery19midwebp from "../images/gallery/19mid.webp";
import gallery19midavif from "../images/gallery/19mid.avif";
import gallery19big from "../images/gallery/19big.png";
import gallery19bigwebp from "../images/gallery/19big.webp";
import gallery19bigavif from "../images/gallery/19big.avif";
import gallery20 from "../images/gallery/20.png";
import gallery20webp from "../images/gallery/20.webp";
import gallery20avif from "../images/gallery/20.avif";
import gallery20mid from "../images/gallery/20mid.png";
import gallery20midwebp from "../images/gallery/20mid.webp";
import gallery20midavif from "../images/gallery/20mid.avif";
import gallery20big from "../images/gallery/20big.png";
import gallery20bigwebp from "../images/gallery/20big.webp";
import gallery20bigavif from "../images/gallery/20big.avif";
import gallery21 from "../images/gallery/21.png";
import gallery21webp from "../images/gallery/21.webp";
import gallery21avif from "../images/gallery/21.avif";
import gallery21mid from "../images/gallery/21mid.png";
import gallery21midwebp from "../images/gallery/21mid.webp";
import gallery21midavif from "../images/gallery/21mid.avif";
import gallery21big from "../images/gallery/21big.png";
import gallery21bigwebp from "../images/gallery/21big.webp";
import gallery21bigavif from "../images/gallery/21big.avif";
import gallery22 from "../images/gallery/22.png";
import gallery22webp from "../images/gallery/22.webp";
import gallery22avif from "../images/gallery/22.avif";
import gallery22mid from "../images/gallery/22mid.png";
import gallery22midwebp from "../images/gallery/22mid.webp";
import gallery22midavif from "../images/gallery/22mid.avif";
import gallery22big from "../images/gallery/22big.png";
import gallery22bigwebp from "../images/gallery/22big.webp";
import gallery22bigavif from "../images/gallery/22big.avif";
import gallery23 from "../images/gallery/23.png";
import gallery23webp from "../images/gallery/23.webp";
import gallery23avif from "../images/gallery/23.avif";
import gallery23mid from "../images/gallery/23mid.png";
import gallery23midwebp from "../images/gallery/23mid.webp";
import gallery23midavif from "../images/gallery/23mid.avif";
import gallery23big from "../images/gallery/23big.png";
import gallery23bigwebp from "../images/gallery/23big.webp";
import gallery23bigavif from "../images/gallery/23big.avif";
import gallery24 from "../images/gallery/24.png";
import gallery24webp from "../images/gallery/24.webp";
import gallery24avif from "../images/gallery/24.avif";
import gallery24mid from "../images/gallery/24mid.png";
import gallery24midwebp from "../images/gallery/24mid.webp";
import gallery24midavif from "../images/gallery/24mid.avif";
import gallery24big from "../images/gallery/24big.png";
import gallery24bigwebp from "../images/gallery/24big.webp";
import gallery24bigavif from "../images/gallery/24big.avif";
import gallery25 from "../images/gallery/25.png";
import gallery25webp from "../images/gallery/25.webp";
import gallery25avif from "../images/gallery/25.avif";
import gallery25mid from "../images/gallery/25mid.png";
import gallery25midwebp from "../images/gallery/25mid.webp";
import gallery25midavif from "../images/gallery/25mid.avif";
import gallery25big from "../images/gallery/25big.png";
import gallery25bigwebp from "../images/gallery/25big.webp";
import gallery25bigavif from "../images/gallery/25big.avif";
import gallery26 from "../images/gallery/26.png";
import gallery26webp from "../images/gallery/26.webp";
import gallery26avif from "../images/gallery/26.avif";
import gallery26mid from "../images/gallery/26mid.png";
import gallery26midwebp from "../images/gallery/26mid.webp";
import gallery26midavif from "../images/gallery/26mid.avif";
import gallery26big from "../images/gallery/26big.png";
import gallery26bigwebp from "../images/gallery/26big.webp";
import gallery26bigavif from "../images/gallery/26big.avif";
import gallery27 from "../images/gallery/27.png";
import gallery27webp from "../images/gallery/27.webp";
import gallery27avif from "../images/gallery/27.avif";
import gallery27mid from "../images/gallery/27mid.png";
import gallery27midwebp from "../images/gallery/27mid.webp";
import gallery27midavif from "../images/gallery/27mid.avif";
import gallery27big from "../images/gallery/27big.png";
import gallery27bigwebp from "../images/gallery/27big.webp";
import gallery27bigavif from "../images/gallery/27big.avif";
import gallery28 from "../images/gallery/28.png";
import gallery28webp from "../images/gallery/28.webp";
import gallery28avif from "../images/gallery/28.avif";
import gallery28mid from "../images/gallery/28mid.png";
import gallery28midwebp from "../images/gallery/28mid.webp";
import gallery28midavif from "../images/gallery/28mid.avif";
import gallery28big from "../images/gallery/28big.png";
import gallery28bigwebp from "../images/gallery/28big.webp";
import gallery28bigavif from "../images/gallery/28big.avif";
import gallery29 from "../images/gallery/29.png";
import gallery29webp from "../images/gallery/29.webp";
import gallery29avif from "../images/gallery/29.avif";
import gallery29mid from "../images/gallery/29mid.png";
import gallery29midwebp from "../images/gallery/29mid.webp";
import gallery29midavif from "../images/gallery/29mid.avif";
import gallery29big from "../images/gallery/29big.png";
import gallery29bigwebp from "../images/gallery/29big.webp";
import gallery29bigavif from "../images/gallery/29big.avif";
import gallery30 from "../images/gallery/30.png";
import gallery30webp from "../images/gallery/30.webp";
import gallery30avif from "../images/gallery/30.avif";
import gallery30mid from "../images/gallery/30mid.png";
import gallery30midwebp from "../images/gallery/30mid.webp";
import gallery30midavif from "../images/gallery/30mid.avif";
import gallery30big from "../images/gallery/30big.png";
import gallery30bigwebp from "../images/gallery/30big.webp";
import gallery30bigavif from "../images/gallery/30big.avif";
import gallery31 from "../images/gallery/31.png";
import gallery31webp from "../images/gallery/31.webp";
import gallery31avif from "../images/gallery/31.avif";
import gallery31mid from "../images/gallery/31mid.png";
import gallery31midwebp from "../images/gallery/31mid.webp";
import gallery31midavif from "../images/gallery/31mid.avif";
import gallery31big from "../images/gallery/31big.png";
import gallery31bigwebp from "../images/gallery/31big.webp";
import gallery31bigavif from "../images/gallery/31big.avif";
import gallery32 from "../images/gallery/32.png";
import gallery32webp from "../images/gallery/32.webp";
import gallery32avif from "../images/gallery/32.avif";
import gallery32mid from "../images/gallery/32mid.png";
import gallery32midwebp from "../images/gallery/32mid.webp";
import gallery32midavif from "../images/gallery/32mid.avif";
import gallery32big from "../images/gallery/32big.png";
import gallery32bigwebp from "../images/gallery/32big.webp";
import gallery32bigavif from "../images/gallery/32big.avif";
import gallery33 from "../images/gallery/33.png";
import gallery33webp from "../images/gallery/33.webp";
import gallery33avif from "../images/gallery/33.avif";
import gallery33mid from "../images/gallery/33mid.png";
import gallery33midwebp from "../images/gallery/33mid.webp";
import gallery33midavif from "../images/gallery/33mid.avif";
import gallery33big from "../images/gallery/33big.png";
import gallery33bigwebp from "../images/gallery/33big.webp";
import gallery33bigavif from "../images/gallery/33big.avif";
import gallery34 from "../images/gallery/34.png";
import gallery34webp from "../images/gallery/34.webp";
import gallery34avif from "../images/gallery/34.avif";
import gallery34mid from "../images/gallery/34mid.png";
import gallery34midwebp from "../images/gallery/34mid.webp";
import gallery34midavif from "../images/gallery/34mid.avif";
import gallery34big from "../images/gallery/34big.png";
import gallery34bigwebp from "../images/gallery/34big.webp";
import gallery34bigavif from "../images/gallery/34big.avif";
import gallery35 from "../images/gallery/35.png";
import gallery35webp from "../images/gallery/35.webp";
import gallery35avif from "../images/gallery/35.avif";
import gallery35mid from "../images/gallery/35mid.png";
import gallery35midwebp from "../images/gallery/35mid.webp";
import gallery35midavif from "../images/gallery/35mid.avif";
import gallery35big from "../images/gallery/35big.png";
import gallery35bigwebp from "../images/gallery/35big.webp";
import gallery35bigavif from "../images/gallery/35big.avif";
import gallery36 from "../images/gallery/36.png";
import gallery36webp from "../images/gallery/36.webp";
import gallery36avif from "../images/gallery/36.avif";
import gallery36mid from "../images/gallery/36mid.png";
import gallery36midwebp from "../images/gallery/36mid.webp";
import gallery36midavif from "../images/gallery/36mid.avif";
import gallery36big from "../images/gallery/36big.png";
import gallery36bigwebp from "../images/gallery/36big.webp";
import gallery36bigavif from "../images/gallery/36big.avif";
import gallery37 from "../images/gallery/37.png";
import gallery37webp from "../images/gallery/37.webp";
import gallery37avif from "../images/gallery/37.avif";
import gallery37mid from "../images/gallery/37mid.png";
import gallery37midwebp from "../images/gallery/37mid.webp";
import gallery37midavif from "../images/gallery/37mid.avif";
import gallery37big from "../images/gallery/37big.png";
import gallery37bigwebp from "../images/gallery/37big.webp";
import gallery37bigavif from "../images/gallery/37big.avif";
import gallery38 from "../images/gallery/38.png";
import gallery38webp from "../images/gallery/38.webp";
import gallery38avif from "../images/gallery/38.avif";
import gallery38mid from "../images/gallery/38mid.png";
import gallery38midwebp from "../images/gallery/38mid.webp";
import gallery38midavif from "../images/gallery/38mid.avif";
import gallery38big from "../images/gallery/38big.png";
import gallery38bigwebp from "../images/gallery/38big.webp";
import gallery38bigavif from "../images/gallery/38big.avif";
import gallery39 from "../images/gallery/39.png";
import gallery39webp from "../images/gallery/39.webp";
import gallery39avif from "../images/gallery/39.avif";
import gallery39mid from "../images/gallery/39mid.png";
import gallery39midwebp from "../images/gallery/39mid.webp";
import gallery39midavif from "../images/gallery/39mid.avif";
import gallery39big from "../images/gallery/39big.png";
import gallery39bigwebp from "../images/gallery/39big.webp";
import gallery39bigavif from "../images/gallery/39big.avif";
import gallery40 from "../images/gallery/40.png";
import gallery40webp from "../images/gallery/40.webp";
import gallery40avif from "../images/gallery/40.avif";
import gallery40mid from "../images/gallery/40mid.png";
import gallery40midwebp from "../images/gallery/40mid.webp";
import gallery40midavif from "../images/gallery/40mid.avif";
import gallery40big from "../images/gallery/40big.png";
import gallery40bigwebp from "../images/gallery/40big.webp";
import gallery40bigavif from "../images/gallery/40big.avif";
import gallery41 from "../images/gallery/41.png";
import gallery41webp from "../images/gallery/41.webp";
import gallery41avif from "../images/gallery/41.avif";
import gallery41mid from "../images/gallery/41mid.png";
import gallery41midwebp from "../images/gallery/41mid.webp";
import gallery41midavif from "../images/gallery/41mid.avif";
import gallery41big from "../images/gallery/41big.png";
import gallery41bigwebp from "../images/gallery/41big.webp";
import gallery41bigavif from "../images/gallery/41big.avif";
import gallery42 from "../images/gallery/42.png";
import gallery42webp from "../images/gallery/42.webp";
import gallery42avif from "../images/gallery/42.avif";
import gallery42mid from "../images/gallery/42mid.png";
import gallery42midwebp from "../images/gallery/42mid.webp";
import gallery42midavif from "../images/gallery/42mid.avif";
import gallery42big from "../images/gallery/42big.png";
import gallery42bigwebp from "../images/gallery/42big.webp";
import gallery42bigavif from "../images/gallery/42big.avif";
import gallery43 from "../images/gallery/43.png";
import gallery43webp from "../images/gallery/43.webp";
import gallery43avif from "../images/gallery/43.avif";
import gallery43mid from "../images/gallery/43mid.png";
import gallery43midwebp from "../images/gallery/43mid.webp";
import gallery43midavif from "../images/gallery/43mid.avif";
import gallery43big from "../images/gallery/43big.png";
import gallery43bigwebp from "../images/gallery/43big.webp";
import gallery43bigavif from "../images/gallery/43big.avif";

export const GalleryContext = React.createContext();

export const gallery = [
  {
    key: 1,
    mini: `${gallery1}`,
    big: `${gallery1big}`,
    miniwebp: `${gallery1webp}`,
    miniavif: `${gallery1avif}`,
    bigwebp: `${gallery1bigwebp}`,
    bigavif: `${gallery1bigavif}`,
    mid: `${gallery1mid}`,
    midwebp: `${gallery1midwebp}`,
    midavif: `${gallery1midavif}`,
  },
  {
    key: 2,
    mini: `${gallery2}`,
    big: `${gallery2big}`,
    miniwebp: `${gallery2webp}`,
    miniavif: `${gallery2avif}`,
    bigwebp: `${gallery2bigwebp}`,
    bigavif: `${gallery2bigavif}`,
    mid: `${gallery2mid}`,
    midwebp: `${gallery2midwebp}`,
    midavif: `${gallery2midavif}`,
  },
  {
    key: 3,
    mini: `${gallery3}`,
    big: `${gallery3big}`,
    miniwebp: `${gallery3webp}`,
    miniavif: `${gallery3avif}`,
    bigwebp: `${gallery3bigwebp}`,
    bigavif: `${gallery3bigavif}`,
    mid: `${gallery3mid}`,
    midwebp: `${gallery3midwebp}`,
    midavif: `${gallery3midavif}`,
  },
  {
    key: 4,
    mini: `${gallery4}`,
    big: `${gallery4big}`,
    miniwebp: `${gallery4webp}`,
    miniavif: `${gallery4avif}`,
    bigwebp: `${gallery4bigwebp}`,
    bigavif: `${gallery4bigavif}`,
    mid: `${gallery4mid}`,
    midwebp: `${gallery4midwebp}`,
    midavif: `${gallery4midavif}`,
  },
  {
    key: 5,
    mini: `${gallery5}`,
    big: `${gallery5big}`,
    miniwebp: `${gallery5webp}`,
    miniavif: `${gallery5avif}`,
    bigwebp: `${gallery5bigwebp}`,
    bigavif: `${gallery5bigavif}`,
    mid: `${gallery5mid}`,
    midwebp: `${gallery5midwebp}`,
    midavif: `${gallery5midavif}`,
  },
  {
    key: 6,
    mini: `${gallery6}`,
    big: `${gallery6big}`,
    miniwebp: `${gallery6webp}`,
    miniavif: `${gallery6avif}`,
    bigwebp: `${gallery6bigwebp}`,
    bigavif: `${gallery6bigavif}`,
    mid: `${gallery6mid}`,
    midwebp: `${gallery6midwebp}`,
    midavif: `${gallery6midavif}`,
  },
  {
    key: 7,
    mini: `${gallery7}`,
    big: `${gallery7big}`,
    miniwebp: `${gallery7webp}`,
    miniavif: `${gallery7avif}`,
    bigwebp: `${gallery7bigwebp}`,
    bigavif: `${gallery7bigavif}`,
    mid: `${gallery7mid}`,
    midwebp: `${gallery7midwebp}`,
    midavif: `${gallery7midavif}`,
  },
  {
    key: 8,
    mini: `${gallery8}`,
    big: `${gallery8big}`,
    miniwebp: `${gallery8webp}`,
    miniavif: `${gallery8avif}`,
    bigwebp: `${gallery8bigwebp}`,
    bigavif: `${gallery8bigavif}`,
    mid: `${gallery8mid}`,
    midwebp: `${gallery8midwebp}`,
    midavif: `${gallery8midavif}`,
  },
  {
    key: 9,
    mini: `${gallery9}`,
    big: `${gallery9big}`,
    miniwebp: `${gallery9webp}`,
    miniavif: `${gallery9avif}`,
    bigwebp: `${gallery9bigwebp}`,
    bigavif: `${gallery9bigavif}`,
    mid: `${gallery9mid}`,
    midwebp: `${gallery9midwebp}`,
    midavif: `${gallery9midavif}`,
  },
  {
    key: 10,
    mini: `${gallery10}`,
    big: `${gallery10big}`,
    miniwebp: `${gallery10webp}`,
    miniavif: `${gallery10avif}`,
    bigwebp: `${gallery10bigwebp}`,
    bigavif: `${gallery10bigavif}`,
    mid: `${gallery10mid}`,
    midwebp: `${gallery10midwebp}`,
    midavif: `${gallery10midavif}`,
  },
  {
    key: 11,
    mini: `${gallery11}`,
    big: `${gallery11big}`,
    miniwebp: `${gallery11webp}`,
    miniavif: `${gallery11avif}`,
    bigwebp: `${gallery11bigwebp}`,
    bigavif: `${gallery11bigavif}`,
    mid: `${gallery11mid}`,
    midwebp: `${gallery11midwebp}`,
    midavif: `${gallery11midavif}`,
  },
  {
    key: 12,
    mini: `${gallery12}`,
    big: `${gallery12big}`,
    miniwebp: `${gallery12webp}`,
    miniavif: `${gallery12avif}`,
    bigwebp: `${gallery12bigwebp}`,
    bigavif: `${gallery12bigavif}`,
    mid: `${gallery12mid}`,
    midwebp: `${gallery12midwebp}`,
    midavif: `${gallery12midavif}`,
  },
  {
    key: 13,
    mini: `${gallery13}`,
    big: `${gallery13big}`,
    miniwebp: `${gallery13webp}`,
    miniavif: `${gallery13avif}`,
    bigwebp: `${gallery13bigwebp}`,
    bigavif: `${gallery13bigavif}`,
    mid: `${gallery13mid}`,
    midwebp: `${gallery13midwebp}`,
    midavif: `${gallery13midavif}`,
  },
  {
    key: 14,
    mini: `${gallery14}`,
    big: `${gallery14big}`,
    miniwebp: `${gallery14webp}`,
    miniavif: `${gallery14avif}`,
    bigwebp: `${gallery14bigwebp}`,
    bigavif: `${gallery14bigavif}`,
    mid: `${gallery14mid}`,
    midwebp: `${gallery14midwebp}`,
    midavif: `${gallery14midavif}`,
  },
  {
    key: 15,
    mini: `${gallery15}`,
    big: `${gallery15big}`,
    miniwebp: `${gallery15webp}`,
    miniavif: `${gallery15avif}`,
    bigwebp: `${gallery15bigwebp}`,
    bigavif: `${gallery15bigavif}`,
    mid: `${gallery15mid}`,
    midwebp: `${gallery15midwebp}`,
    midavif: `${gallery15midavif}`,
  },
  {
    key: 16,
    mini: `${gallery16}`,
    big: `${gallery16big}`,
    miniwebp: `${gallery16webp}`,
    miniavif: `${gallery16avif}`,
    bigwebp: `${gallery16bigwebp}`,
    bigavif: `${gallery16bigavif}`,
    mid: `${gallery16mid}`,
    midwebp: `${gallery16midwebp}`,
    midavif: `${gallery16midavif}`,
  },
  {
    key: 17,
    mini: `${gallery17}`,
    big: `${gallery17big}`,
    miniwebp: `${gallery17webp}`,
    miniavif: `${gallery17avif}`,
    bigwebp: `${gallery17bigwebp}`,
    bigavif: `${gallery17bigavif}`,
    mid: `${gallery17mid}`,
    midwebp: `${gallery17midwebp}`,
    midavif: `${gallery17midavif}`,
  },
  {
    key: 18,
    mini: `${gallery18}`,
    big: `${gallery18big}`,
    miniwebp: `${gallery18webp}`,
    miniavif: `${gallery18avif}`,
    bigwebp: `${gallery18bigwebp}`,
    bigavif: `${gallery18bigavif}`,
    mid: `${gallery18mid}`,
    midwebp: `${gallery18midwebp}`,
    midavif: `${gallery18midavif}`,
  },
  {
    key: 19,
    mini: `${gallery19}`,
    big: `${gallery19big}`,
    miniwebp: `${gallery19webp}`,
    miniavif: `${gallery19avif}`,
    bigwebp: `${gallery19bigwebp}`,
    bigavif: `${gallery19bigavif}`,
    mid: `${gallery19mid}`,
    midwebp: `${gallery19midwebp}`,
    midavif: `${gallery19midavif}`,
  },
  {
    key: 20,
    mini: `${gallery20}`,
    big: `${gallery20big}`,
    miniwebp: `${gallery20webp}`,
    miniavif: `${gallery20avif}`,
    bigwebp: `${gallery20bigwebp}`,
    bigavif: `${gallery20bigavif}`,
    mid: `${gallery20mid}`,
    midwebp: `${gallery20midwebp}`,
    midavif: `${gallery20midavif}`,
  },
  {
    key: 21,
    mini: `${gallery21}`,
    big: `${gallery21big}`,
    miniwebp: `${gallery21webp}`,
    miniavif: `${gallery21avif}`,
    bigwebp: `${gallery21bigwebp}`,
    bigavif: `${gallery21bigavif}`,
    mid: `${gallery21mid}`,
    midwebp: `${gallery21midwebp}`,
    midavif: `${gallery21midavif}`,
  },
  {
    key: 22,
    mini: `${gallery22}`,
    big: `${gallery22big}`,
    miniwebp: `${gallery22webp}`,
    miniavif: `${gallery22avif}`,
    bigwebp: `${gallery22bigwebp}`,
    bigavif: `${gallery22bigavif}`,
    mid: `${gallery22mid}`,
    midwebp: `${gallery22midwebp}`,
    midavif: `${gallery22midavif}`,
  },
  {
    key: 23,
    mini: `${gallery23}`,
    big: `${gallery23big}`,
    miniwebp: `${gallery23webp}`,
    miniavif: `${gallery23avif}`,
    bigwebp: `${gallery23bigwebp}`,
    bigavif: `${gallery23bigavif}`,
    mid: `${gallery23mid}`,
    midwebp: `${gallery23midwebp}`,
    midavif: `${gallery23midavif}`,
  },
  {
    key: 24,
    mini: `${gallery24}`,
    big: `${gallery24big}`,
    miniwebp: `${gallery24webp}`,
    miniavif: `${gallery24avif}`,
    bigwebp: `${gallery24bigwebp}`,
    bigavif: `${gallery24bigavif}`,
    mid: `${gallery24mid}`,
    midwebp: `${gallery24midwebp}`,
    midavif: `${gallery24midavif}`,
  },
  {
    key: 25,
    mini: `${gallery25}`,
    big: `${gallery25big}`,
    miniwebp: `${gallery25webp}`,
    miniavif: `${gallery25avif}`,
    bigwebp: `${gallery25bigwebp}`,
    bigavif: `${gallery25bigavif}`,
    mid: `${gallery25mid}`,
    midwebp: `${gallery25midwebp}`,
    midavif: `${gallery25midavif}`,
  },
  {
    key: 26,
    mini: `${gallery26}`,
    big: `${gallery26big}`,
    miniwebp: `${gallery26webp}`,
    miniavif: `${gallery26avif}`,
    bigwebp: `${gallery26bigwebp}`,
    bigavif: `${gallery26bigavif}`,
    mid: `${gallery26mid}`,
    midwebp: `${gallery26midwebp}`,
    midavif: `${gallery26midavif}`,
  },
  {
    key: 27,
    mini: `${gallery27}`,
    big: `${gallery27big}`,
    miniwebp: `${gallery27webp}`,
    miniavif: `${gallery27avif}`,
    bigwebp: `${gallery27bigwebp}`,
    bigavif: `${gallery27bigavif}`,
    mid: `${gallery27mid}`,
    midwebp: `${gallery27midwebp}`,
    midavif: `${gallery27midavif}`,
  },
  {
    key: 28,
    mini: `${gallery28}`,
    big: `${gallery28big}`,
    miniwebp: `${gallery28webp}`,
    miniavif: `${gallery28avif}`,
    bigwebp: `${gallery28bigwebp}`,
    bigavif: `${gallery28bigavif}`,
    mid: `${gallery28mid}`,
    midwebp: `${gallery28midwebp}`,
    midavif: `${gallery28midavif}`,
  },
  {
    key: 29,
    mini: `${gallery29}`,
    big: `${gallery29big}`,
    miniwebp: `${gallery29webp}`,
    miniavif: `${gallery29avif}`,
    bigwebp: `${gallery29bigwebp}`,
    bigavif: `${gallery29bigavif}`,
    mid: `${gallery29mid}`,
    midwebp: `${gallery29midwebp}`,
    midavif: `${gallery29midavif}`,
  },
  {
    key: 30,
    mini: `${gallery30}`,
    big: `${gallery30big}`,
    miniwebp: `${gallery30webp}`,
    miniavif: `${gallery30avif}`,
    bigwebp: `${gallery30bigwebp}`,
    bigavif: `${gallery30bigavif}`,
    mid: `${gallery30mid}`,
    midwebp: `${gallery30midwebp}`,
    midavif: `${gallery30midavif}`,
  },
  {
    key: 31,
    mini: `${gallery31}`,
    big: `${gallery31big}`,
    miniwebp: `${gallery31webp}`,
    miniavif: `${gallery31avif}`,
    bigwebp: `${gallery31bigwebp}`,
    bigavif: `${gallery31bigavif}`,
    mid: `${gallery31mid}`,
    midwebp: `${gallery31midwebp}`,
    midavif: `${gallery31midavif}`,
  },
  {
    key: 32,
    mini: `${gallery32}`,
    big: `${gallery32big}`,
    miniwebp: `${gallery32webp}`,
    miniavif: `${gallery32avif}`,
    bigwebp: `${gallery32bigwebp}`,
    bigavif: `${gallery32bigavif}`,
    mid: `${gallery32mid}`,
    midwebp: `${gallery32midwebp}`,
    midavif: `${gallery32midavif}`,
  },
  {
    key: 33,
    mini: `${gallery33}`,
    big: `${gallery33big}`,
    miniwebp: `${gallery33webp}`,
    miniavif: `${gallery33avif}`,
    bigwebp: `${gallery33bigwebp}`,
    bigavif: `${gallery33bigavif}`,
    mid: `${gallery33mid}`,
    midwebp: `${gallery33midwebp}`,
    midavif: `${gallery33midavif}`,
  },
  {
    key: 34,
    mini: `${gallery34}`,
    big: `${gallery34big}`,
    miniwebp: `${gallery34webp}`,
    miniavif: `${gallery34avif}`,
    bigwebp: `${gallery34bigwebp}`,
    bigavif: `${gallery34bigavif}`,
    mid: `${gallery34mid}`,
    midwebp: `${gallery34midwebp}`,
    midavif: `${gallery34midavif}`,
  },
  {
    key: 35,
    mini: `${gallery35}`,
    big: `${gallery35big}`,
    miniwebp: `${gallery35webp}`,
    miniavif: `${gallery35avif}`,
    bigwebp: `${gallery35bigwebp}`,
    bigavif: `${gallery35bigavif}`,
    mid: `${gallery35mid}`,
    midwebp: `${gallery35midwebp}`,
    midavif: `${gallery35midavif}`,
  },
  {
    key: 36,
    mini: `${gallery36}`,
    big: `${gallery36big}`,
    miniwebp: `${gallery36webp}`,
    miniavif: `${gallery36avif}`,
    bigwebp: `${gallery36bigwebp}`,
    bigavif: `${gallery36bigavif}`,
    mid: `${gallery36mid}`,
    midwebp: `${gallery36midwebp}`,
    midavif: `${gallery36midavif}`,
  },
  {
    key: 37,
    mini: `${gallery37}`,
    big: `${gallery37big}`,
    miniwebp: `${gallery37webp}`,
    miniavif: `${gallery37avif}`,
    bigwebp: `${gallery37bigwebp}`,
    bigavif: `${gallery37bigavif}`,
    mid: `${gallery37mid}`,
    midwebp: `${gallery37midwebp}`,
    midavif: `${gallery37midavif}`,
  },
  {
    key: 38,
    mini: `${gallery38}`,
    big: `${gallery38big}`,
    miniwebp: `${gallery38webp}`,
    miniavif: `${gallery38avif}`,
    bigwebp: `${gallery38bigwebp}`,
    bigavif: `${gallery38bigavif}`,
    mid: `${gallery38mid}`,
    midwebp: `${gallery38midwebp}`,
    midavif: `${gallery38midavif}`,
  },
  {
    key: 39,
    mini: `${gallery39}`,
    big: `${gallery39big}`,
    miniwebp: `${gallery39webp}`,
    miniavif: `${gallery39avif}`,
    bigwebp: `${gallery39bigwebp}`,
    bigavif: `${gallery39bigavif}`,
    mid: `${gallery39mid}`,
    midwebp: `${gallery39midwebp}`,
    midavif: `${gallery39midavif}`,
  },
  {
    key: 40,
    mini: `${gallery40}`,
    big: `${gallery40big}`,
    miniwebp: `${gallery40webp}`,
    miniavif: `${gallery40avif}`,
    bigwebp: `${gallery40bigwebp}`,
    bigavif: `${gallery40bigavif}`,
    mid: `${gallery40mid}`,
    midwebp: `${gallery40midwebp}`,
    midavif: `${gallery40midavif}`,
  },
  {
    key: 41,
    mini: `${gallery41}`,
    big: `${gallery41big}`,
    miniwebp: `${gallery41webp}`,
    miniavif: `${gallery41avif}`,
    bigwebp: `${gallery41bigwebp}`,
    bigavif: `${gallery41bigavif}`,
    mid: `${gallery41mid}`,
    midwebp: `${gallery41midwebp}`,
    midavif: `${gallery41midavif}`,
  },
  {
    key: 42,
    mini: `${gallery42}`,
    big: `${gallery42big}`,
    miniwebp: `${gallery42webp}`,
    miniavif: `${gallery42avif}`,
    bigwebp: `${gallery42bigwebp}`,
    bigavif: `${gallery42bigavif}`,
    mid: `${gallery42mid}`,
    midwebp: `${gallery42midwebp}`,
    midavif: `${gallery42midavif}`,
  },
  {
    key: 43,
    mini: `${gallery43}`,
    big: `${gallery43big}`,
    miniwebp: `${gallery43webp}`,
    miniavif: `${gallery43avif}`,
    bigwebp: `${gallery43bigwebp}`,
    bigavif: `${gallery43bigavif}`,
    mid: `${gallery43mid}`,
    midwebp: `${gallery43midwebp}`,
    midavif: `${gallery43midavif}`,
  },
];
