import React from "react";
import price1 from "../images/price/1.png";
import price1webp from "../images/price/1.webp";
import price1avif from "../images/price/1.avif";
import price2 from "../images/price/2.png";
import price2webp from "../images/price/2.webp";
import price2avif from "../images/price/2.avif";
import price3 from "../images/price/3.png";
import price3webp from "../images/price/3.webp";
import price3avif from "../images/price/3.avif";

export const PriceContext = React.createContext();

export const price = [
  {
    key: 1,
    src: `${price1}`,
    webp: `${price1webp}`,
    avif: `${price1avif}`,
    title: "Индивидуальное занятие",
    subtitle: "1 занятие 60 минут",
    text: [
      "Хотите, чтобы ребёнок достигал результата быстрее или просто привыкли к индивидуальному подходу?",
      "Тогда индивидуальные занятия подойдут вашему юному танцору.",
      "Педагог максимально быстро раскроет потенциал, поможет научиться важным элементам.",
      'Разовые индивидуальные занятия при посещении танцев по абонементу значительно "поднимут" уровень подготовки.',
      "Сколько длятся занятие?",
      " одно занятие длится 60 минут.",
      "Что с собой брать на пробное занятие?",
      "Ребенок может прийти в любой комфортной форме: футболка, шортики, лосины или леггинсы. Если у вас есть хореографический купальник, то приходите в нем.",
      "На ножках - носочки или балетки.",
    ],
    price: "1500 руб.",
  },
  {
    key: 2,
    src: `${price2}`,
    webp: `${price2webp}`,
    avif: `${price2avif}`,
    title: "Разовое занятие",
    subtitle: "1 занятие 60 минут",
    text: [
      'Одно разовое занятие танцами в театре танца "Переворот".',
      "Напишите нам, мы подберём филиал и группу для вашего ребенка по возрасту и уровню подготовки.",
      "Профессионал хореограф поможет ребенку быстро адаптироваться в коллективе и раскрыть его потенциал.",
      "Занятие длится час в вечернее время - расписание филиалов уточняйте.",
      "Что с собой брать на пробное занятие?",
      "Ребенок может прийти в любой комфортной форме: футболка, шортики, лосины или леггинсы. Если у вас есть хореографический купальник, то приходите в нем.",
      "На ножках - носочки или балетки.",
    ],
    price: "350 руб.",
  },
  {
    key: 3,
    src: `${price3}`,
    webp: `${price3webp}`,
    avif: `${price3avif}`,
    title: "Абонемент",
    subtitle: "Занятия 2-3 раза в неделю по 60 минут",
    text: [
      "Фиксированная стоимость абонемента на месяц.",
      "8/12 занятий в месяц + 1 бонусное (если в месяце больше дней).",
      "Занятие длится час в вечернее время - расписание филиалов уточняйте.",
      "Что с собой брать на пробное занятие?",
      "Ребенок может прийти в любой комфортной форме: футболка, шортики, лосины или леггинсы. Если у вас есть хореографический купальник, то приходите в нем.",
      "На ножках - носочки или балетки.",
    ],
    price: "2800/3500 руб.",
  },
];
