import "./Footer.css";
import React from "react";
import svpLogo from "../../images/icon/svplogo.png";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="footer__box">
        <picture>
          <source srcSet={props.logowebp} type="image/webp" />
          <source srcSet={props.logoavif} type="image/avif" />
          <source srcSet={props.logo} type="image/png" />
          <img
            className="footer__logo"
            src={props.logo}
            alt="logo"
            type="image/png"
          />
        </picture>
        <div className="footer__menu-box">
          <p className="footer__title">Меню</p>
          <nav className="footer__menu">
            <a className="footer__link" href="#gallery">
              Галерея
            </a>
            <a className="footer__link" href="#video">
              Видео
            </a>
            <a className="footer__link" href="#comanda">
              Команда
            </a>
            <a className="footer__link" href="#price">
              Цены
            </a>
            <a className="footer__link" href="#rewiev">
              Отзывы
            </a>
            <a className="footer__link" href="#diploms">
              Дипломы
            </a>
            <a className="footer__link" href="#contacts">
              Контакты
            </a>
          </nav>
        </div>
        <div className="footer__about-box">
          <p className="footer__title">Реквизиты</p>
          <p className="footer__subtitle">ИНН: 344553401661</p>
          <p className="footer__subtitle">ОГРНИП: 318344300036913</p>
          <button
            className="footer__policy"
            onClick={() => props.setOpenPopupPolicy(true)}
          >
            ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
          </button>
          <button
            className="footer__oferta"
            onClick={() => props.setOpenPopupOferta(true)}
          >
            Публичный договор оферта
          </button>
        </div>
        <div className="footer__tel-box">
          <a className="footer__tel" href="tel:+78002016762">
            8 (800) 201-67-62
          </a>
          <button
            className="footer__tel-button"
            onClick={() => props.setOpenPopupForm(true)}
            type="button"
          >
            Заказать звонок
          </button>
          <div className="footer__box-mess">
            <a
              className="footer__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/79871856569?text=Здравствуйте.+Хочу+записать+своего+ребенка+на+занятие."
            >
              {null}
            </a>
            <a
              className="footer__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://t.me/perevorot_dance"
            >
              {null}
            </a>
            <a
              className="footer__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://vk.com/perevorot_dance"
            >
              {null}
            </a>
          </div>
        </div>
      </div>
      <a
        className="footer_svp"
        href="https://popov.media/"
        target="_blank"
        rel="noreferrer"
      >
        <p className="footer__svp-text">Разработано и продвигается</p>
        <img className="footer__svp-logo" src={svpLogo} alt="SVP" />
      </a>
    </footer>
  );
}

export default Footer;
