import React from "react";
import video1 from "../images/video/1.png";
import video1webp from "../images/video/1.webp";
import video1avif from "../images/video/1.avif";
import video2 from "../images/video/2.png";
import video2webp from "../images/video/2.webp";
import video2avif from "../images/video/2.avif";
import video3 from "../images/video/3.png";
import video3webp from "../images/video/3.webp";
import video3avif from "../images/video/3.avif";

export const VideoContext = React.createContext();

export const videos = [
  {
    key: 1,
    src: `${video1}`,
    webp: `${video1webp}`,
    avif: `${video1avif}`,
    id: "Az63RrHuDH4",
  },
  {
    key: 2,
    src: `${video2}`,
    webp: `${video2webp}`,
    avif: `${video2avif}`,
    id: "wYbNrYbTiJw",
  },
  {
    key: 3,
    src: `${video3}`,
    webp: `${video3webp}`,
    avif: `${video3avif}`,
    id: "I2IDcz3LS88",
  },
];
