import "./Header.css";
import React from "react";

function Header(props) {
  const [isOpenMenu, setOpenMenu] = React.useState(false);
  return (
    <header className="header">
      <div className="header__main-box">
        <button
          type="button"
          className="header__button-menu"
          onClick={() => setOpenMenu(true)}
        ></button>
        <picture>
          <source srcSet={props.logowebp} type="image/webp" />
          <source srcSet={props.logoavif} type="image/avif" />
          <source srcSet={props.logo} type="image/png" />
          <img
            className="header__logo"
            src={props.logo}
            alt="logo"
            type="image/png"
          />
        </picture>
        <nav
          className={
            isOpenMenu
              ? "header__menu header__menu_mob_active"
              : "header__menu "
          }
        >
          <a
            className="header__link"
            href="#gallery"
            onClick={() => setOpenMenu(false)}
          >
            Галерея
          </a>
          <a
            className="header__link"
            href="#video"
            onClick={() => setOpenMenu(false)}
          >
            Видео
          </a>
          <a
            className="header__link"
            href="#comanda"
            onClick={() => setOpenMenu(false)}
          >
            Преподаватели
          </a>
          <a
            className="header__link"
            href="#price"
            onClick={() => setOpenMenu(false)}
          >
            Цены
          </a>
          <a
            className="header__link"
            href="#rewiev"
            onClick={() => setOpenMenu(false)}
          >
            Отзывы
          </a>
          <a
            className="header__link"
            href="#diploms"
            onClick={() => setOpenMenu(false)}
          >
            Дипломы
          </a>
          <a
            className="header__link"
            href="#contacts"
            onClick={() => setOpenMenu(false)}
          >
            Контакты
          </a>
          <button
            type="button"
            className="header__button-menu_close"
            onClick={() => setOpenMenu(false)}
          ></button>
        </nav>
        <div className="header__box">
          <a className="header__tel" href="tel:+78002016762">
            8 (800) 201-67-62
          </a>
          <button
            className="header__tel-button"
            onClick={() => {
              props.setOpenPopupForm(true);
              window._tmr.push({
                type: "reachGoal",
                id: 3409793,
                goal: "callback",
              });
            }}
            type="button"
          >
            Заказать звонок
          </button>
          <div className="header__box-mess">
            <a
              className="header__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/79871856569?text=Здравствуйте.+Хочу+записать+своего+ребенка+на+занятие."
            >
              {null}
            </a>
            <a
              className="header__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://t.me/perevorot_dance"
            >
              {null}
            </a>
            <a
              className="header__social-link"
              target="_blank"
              rel="noreferrer"
              href="https://vk.com/perevorot_dance"
            >
              {null}
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
