import React from "react";
import diplom1 from "../images/diplom/1.png";
import diplom1webp from "../images/diplom/1.webp";
import diplom1avif from "../images/diplom/1.avif";
import diplom1big from "../images/diplom/1big.png";
import diplom1bigwebp from "../images/diplom/1big.webp";
import diplom1bigavif from "../images/diplom/1big.avif";
import diplom2 from "../images/diplom/2.png";
import diplom2webp from "../images/diplom/2.webp";
import diplom2avif from "../images/diplom/2.avif";
import diplom2big from "../images/diplom/2big.png";
import diplom2bigwebp from "../images/diplom/2big.webp";
import diplom2bigavif from "../images/diplom/2big.avif";
import diplom3 from "../images/diplom/3.png";
import diplom3webp from "../images/diplom/3.webp";
import diplom3avif from "../images/diplom/3.avif";
import diplom3big from "../images/diplom/3big.png";
import diplom3bigwebp from "../images/diplom/3big.webp";
import diplom3bigavif from "../images/diplom/3big.avif";
import diplom4 from "../images/diplom/4.png";
import diplom4webp from "../images/diplom/4.webp";
import diplom4avif from "../images/diplom/4.avif";
import diplom4big from "../images/diplom/4big.png";
import diplom4bigwebp from "../images/diplom/4big.webp";
import diplom4bigavif from "../images/diplom/4big.avif";
import diplom5 from "../images/diplom/5.png";
import diplom5webp from "../images/diplom/5.webp";
import diplom5avif from "../images/diplom/5.avif";
import diplom5big from "../images/diplom/5big.png";
import diplom5bigwebp from "../images/diplom/5big.webp";
import diplom5bigavif from "../images/diplom/5big.avif";
import diplom6 from "../images/diplom/6.png";
import diplom6webp from "../images/diplom/6.webp";
import diplom6avif from "../images/diplom/6.avif";
import diplom6big from "../images/diplom/6big.png";
import diplom6bigwebp from "../images/diplom/6big.webp";
import diplom6bigavif from "../images/diplom/6big.avif";
import diplom7 from "../images/diplom/7.png";
import diplom7webp from "../images/diplom/7.webp";
import diplom7avif from "../images/diplom/7.avif";
import diplom7big from "../images/diplom/7big.png";
import diplom7bigwebp from "../images/diplom/7big.webp";
import diplom7bigavif from "../images/diplom/7big.avif";
import diplom8 from "../images/diplom/8.png";
import diplom8webp from "../images/diplom/8.webp";
import diplom8avif from "../images/diplom/8.avif";
import diplom8big from "../images/diplom/8big.png";
import diplom8bigwebp from "../images/diplom/8big.webp";
import diplom8bigavif from "../images/diplom/8big.avif";
import diplom9 from "../images/diplom/9.png";
import diplom9webp from "../images/diplom/9.webp";
import diplom9avif from "../images/diplom/9.avif";
import diplom9big from "../images/diplom/9big.png";
import diplom9bigwebp from "../images/diplom/9big.webp";
import diplom9bigavif from "../images/diplom/9big.avif";
import diplom10 from "../images/diplom/10.png";
import diplom10webp from "../images/diplom/10.webp";
import diplom10avif from "../images/diplom/10.avif";
import diplom10big from "../images/diplom/10big.png";
import diplom10bigwebp from "../images/diplom/10big.webp";
import diplom10bigavif from "../images/diplom/10big.avif";
import diplom11 from "../images/diplom/11.png";
import diplom11webp from "../images/diplom/11.webp";
import diplom11avif from "../images/diplom/11.avif";
import diplom11big from "../images/diplom/11big.png";
import diplom11bigwebp from "../images/diplom/11big.webp";
import diplom11bigavif from "../images/diplom/11big.avif";
import diplom12 from "../images/diplom/12.png";
import diplom12webp from "../images/diplom/12.webp";
import diplom12avif from "../images/diplom/12.avif";
import diplom12big from "../images/diplom/12big.png";
import diplom12bigwebp from "../images/diplom/12big.webp";
import diplom12bigavif from "../images/diplom/12big.avif";
import diplom13 from "../images/diplom/13.png";
import diplom13webp from "../images/diplom/13.webp";
import diplom13avif from "../images/diplom/13.avif";
import diplom13big from "../images/diplom/13big.png";
import diplom13bigwebp from "../images/diplom/13big.webp";
import diplom13bigavif from "../images/diplom/13big.avif";
import diplom14 from "../images/diplom/14.png";
import diplom14webp from "../images/diplom/14.webp";
import diplom14avif from "../images/diplom/14.avif";
import diplom14big from "../images/diplom/14big.png";
import diplom14bigwebp from "../images/diplom/14big.webp";
import diplom14bigavif from "../images/diplom/14big.avif";
import diplom15 from "../images/diplom/15.png";
import diplom15webp from "../images/diplom/15.webp";
import diplom15avif from "../images/diplom/15.avif";
import diplom15big from "../images/diplom/15big.png";
import diplom15bigwebp from "../images/diplom/15big.webp";
import diplom15bigavif from "../images/diplom/15big.avif";
import diplom16 from "../images/diplom/16.png";
import diplom16webp from "../images/diplom/16.webp";
import diplom16avif from "../images/diplom/16.avif";
import diplom16big from "../images/diplom/16big.png";
import diplom16bigwebp from "../images/diplom/16big.webp";
import diplom16bigavif from "../images/diplom/16big.avif";
import diplom17 from "../images/diplom/17.png";
import diplom17webp from "../images/diplom/17.webp";
import diplom17avif from "../images/diplom/17.avif";
import diplom17big from "../images/diplom/17big.png";
import diplom17bigwebp from "../images/diplom/17big.webp";
import diplom17bigavif from "../images/diplom/17big.avif";
import diplom18 from "../images/diplom/18.png";
import diplom18webp from "../images/diplom/18.webp";
import diplom18avif from "../images/diplom/18.avif";
import diplom18big from "../images/diplom/18big.png";
import diplom18bigwebp from "../images/diplom/18big.webp";
import diplom18bigavif from "../images/diplom/18big.avif";
import diplom19 from "../images/diplom/19.png";
import diplom19webp from "../images/diplom/19.webp";
import diplom19avif from "../images/diplom/19.avif";
import diplom19big from "../images/diplom/19big.png";
import diplom19bigwebp from "../images/diplom/19big.webp";
import diplom19bigavif from "../images/diplom/19big.avif";
import diplom20 from "../images/diplom/20.png";
import diplom20webp from "../images/diplom/20.webp";
import diplom20avif from "../images/diplom/20.avif";
import diplom20big from "../images/diplom/20big.png";
import diplom20bigwebp from "../images/diplom/20big.webp";
import diplom20bigavif from "../images/diplom/20big.avif";
import diplom21 from "../images/diplom/21.png";
import diplom21webp from "../images/diplom/21.webp";
import diplom21avif from "../images/diplom/21.avif";
import diplom21big from "../images/diplom/21big.png";
import diplom21bigwebp from "../images/diplom/21big.webp";
import diplom21bigavif from "../images/diplom/21big.avif";
import diplom22 from "../images/diplom/22.png";
import diplom22webp from "../images/diplom/22.webp";
import diplom22avif from "../images/diplom/22.avif";
import diplom22big from "../images/diplom/22big.png";
import diplom22bigwebp from "../images/diplom/22big.webp";
import diplom22bigavif from "../images/diplom/22big.avif";
import diplom23 from "../images/diplom/23.png";
import diplom23webp from "../images/diplom/23.webp";
import diplom23avif from "../images/diplom/23.avif";
import diplom23big from "../images/diplom/23big.png";
import diplom23bigwebp from "../images/diplom/23big.webp";
import diplom23bigavif from "../images/diplom/23big.avif";
import diplom24 from "../images/diplom/24.png";
import diplom24webp from "../images/diplom/24.webp";
import diplom24avif from "../images/diplom/24.avif";
import diplom24big from "../images/diplom/24big.png";
import diplom24bigwebp from "../images/diplom/24big.webp";
import diplom24bigavif from "../images/diplom/24big.avif";
import diplom25 from "../images/diplom/25.png";
import diplom25webp from "../images/diplom/25.webp";
import diplom25avif from "../images/diplom/25.avif";
import diplom25big from "../images/diplom/25big.png";
import diplom25bigwebp from "../images/diplom/25big.webp";
import diplom25bigavif from "../images/diplom/25big.avif";
import diplom26 from "../images/diplom/26.png";
import diplom26webp from "../images/diplom/26.webp";
import diplom26avif from "../images/diplom/26.avif";
import diplom26big from "../images/diplom/26big.png";
import diplom26bigwebp from "../images/diplom/26big.webp";
import diplom26bigavif from "../images/diplom/26big.avif";
import diplom27 from "../images/diplom/27.png";
import diplom27webp from "../images/diplom/27.webp";
import diplom27avif from "../images/diplom/27.avif";
import diplom27big from "../images/diplom/27big.png";
import diplom27bigwebp from "../images/diplom/27big.webp";
import diplom27bigavif from "../images/diplom/27big.avif";
import diplom28 from "../images/diplom/28.png";
import diplom28webp from "../images/diplom/28.webp";
import diplom28avif from "../images/diplom/28.avif";
import diplom28big from "../images/diplom/28big.png";
import diplom28bigwebp from "../images/diplom/28big.webp";
import diplom28bigavif from "../images/diplom/28big.avif";
import diplom29 from "../images/diplom/29.png";
import diplom29webp from "../images/diplom/29.webp";
import diplom29avif from "../images/diplom/29.avif";
import diplom29big from "../images/diplom/29big.png";
import diplom29bigwebp from "../images/diplom/29big.webp";
import diplom29bigavif from "../images/diplom/29big.avif";
import diplom30 from "../images/diplom/30.png";
import diplom30webp from "../images/diplom/30.webp";
import diplom30avif from "../images/diplom/30.avif";
import diplom30big from "../images/diplom/30big.png";
import diplom30bigwebp from "../images/diplom/30big.webp";
import diplom30bigavif from "../images/diplom/30big.avif";
import diplom31 from "../images/diplom/31.png";
import diplom31webp from "../images/diplom/31.webp";
import diplom31avif from "../images/diplom/31.avif";
import diplom31big from "../images/diplom/31big.png";
import diplom31bigwebp from "../images/diplom/31big.webp";
import diplom31bigavif from "../images/diplom/31big.avif";
import diplom32 from "../images/diplom/32.png";
import diplom32webp from "../images/diplom/32.webp";
import diplom32avif from "../images/diplom/32.avif";
import diplom32big from "../images/diplom/32big.png";
import diplom32bigwebp from "../images/diplom/32big.webp";
import diplom32bigavif from "../images/diplom/32big.avif";
import diplom33 from "../images/diplom/33.png";
import diplom33webp from "../images/diplom/33.webp";
import diplom33avif from "../images/diplom/33.avif";
import diplom33big from "../images/diplom/33big.png";
import diplom33bigwebp from "../images/diplom/33big.webp";
import diplom33bigavif from "../images/diplom/33big.avif";
import diplom34 from "../images/diplom/34.png";
import diplom34webp from "../images/diplom/34.webp";
import diplom34avif from "../images/diplom/34.avif";
import diplom34big from "../images/diplom/34big.png";
import diplom34bigwebp from "../images/diplom/34big.webp";
import diplom34bigavif from "../images/diplom/34big.avif";
import diplom35 from "../images/diplom/35.png";
import diplom35webp from "../images/diplom/35.webp";
import diplom35avif from "../images/diplom/35.avif";
import diplom35big from "../images/diplom/35big.png";
import diplom35bigwebp from "../images/diplom/35big.webp";
import diplom35bigavif from "../images/diplom/35big.avif";
import diplom36 from "../images/diplom/36.png";
import diplom36webp from "../images/diplom/36.webp";
import diplom36avif from "../images/diplom/36.avif";
import diplom36big from "../images/diplom/36big.png";
import diplom36bigwebp from "../images/diplom/36big.webp";
import diplom36bigavif from "../images/diplom/36big.avif";
import diplom37 from "../images/diplom/37.png";
import diplom37webp from "../images/diplom/37.webp";
import diplom37avif from "../images/diplom/37.avif";
import diplom37big from "../images/diplom/37big.png";
import diplom37bigwebp from "../images/diplom/37big.webp";
import diplom37bigavif from "../images/diplom/37big.avif";
import diplom38 from "../images/diplom/38.png";
import diplom38webp from "../images/diplom/38.webp";
import diplom38avif from "../images/diplom/38.avif";
import diplom38big from "../images/diplom/38big.png";
import diplom38bigwebp from "../images/diplom/38big.webp";
import diplom38bigavif from "../images/diplom/38big.avif";
import diplom39 from "../images/diplom/39.png";
import diplom39webp from "../images/diplom/39.webp";
import diplom39avif from "../images/diplom/39.avif";
import diplom39big from "../images/diplom/39big.png";
import diplom39bigwebp from "../images/diplom/39big.webp";
import diplom39bigavif from "../images/diplom/39big.avif";
import diplom40 from "../images/diplom/40.png";
import diplom40webp from "../images/diplom/40.webp";
import diplom40avif from "../images/diplom/40.avif";
import diplom40big from "../images/diplom/40big.png";
import diplom40bigwebp from "../images/diplom/40big.webp";
import diplom40bigavif from "../images/diplom/40big.avif";
import diplom41 from "../images/diplom/41.png";
import diplom41webp from "../images/diplom/41.webp";
import diplom41avif from "../images/diplom/41.avif";
import diplom41big from "../images/diplom/41big.png";
import diplom41bigwebp from "../images/diplom/41big.webp";
import diplom41bigavif from "../images/diplom/41big.avif";
import diplom42 from "../images/diplom/42.png";
import diplom42webp from "../images/diplom/42.webp";
import diplom42avif from "../images/diplom/42.avif";
import diplom42big from "../images/diplom/42big.png";
import diplom42bigwebp from "../images/diplom/42big.webp";
import diplom42bigavif from "../images/diplom/42big.avif";
import diplom43 from "../images/diplom/43.png";
import diplom43webp from "../images/diplom/43.webp";
import diplom43avif from "../images/diplom/43.avif";
import diplom43big from "../images/diplom/43big.png";
import diplom43bigwebp from "../images/diplom/43big.webp";
import diplom43bigavif from "../images/diplom/43big.avif";
import diplom44 from "../images/diplom/44.png";
import diplom44webp from "../images/diplom/44.webp";
import diplom44avif from "../images/diplom/44.avif";
import diplom44big from "../images/diplom/44big.png";
import diplom44bigwebp from "../images/diplom/44big.webp";
import diplom44bigavif from "../images/diplom/44big.avif";
import diplom45 from "../images/diplom/45.png";
import diplom45webp from "../images/diplom/45.webp";
import diplom45avif from "../images/diplom/45.avif";
import diplom45big from "../images/diplom/45big.png";
import diplom45bigwebp from "../images/diplom/45big.webp";
import diplom45bigavif from "../images/diplom/45big.avif";
import diplom46 from "../images/diplom/46.png";
import diplom46webp from "../images/diplom/46.webp";
import diplom46avif from "../images/diplom/46.avif";
import diplom46big from "../images/diplom/46big.png";
import diplom46bigwebp from "../images/diplom/46big.webp";
import diplom46bigavif from "../images/diplom/46big.avif";
import diplom47 from "../images/diplom/47.png";
import diplom47webp from "../images/diplom/47.webp";
import diplom47avif from "../images/diplom/47.avif";
import diplom47big from "../images/diplom/47big.png";
import diplom47bigwebp from "../images/diplom/47big.webp";
import diplom47bigavif from "../images/diplom/47big.avif";
import diplom48 from "../images/diplom/48.png";
import diplom48webp from "../images/diplom/48.webp";
import diplom48avif from "../images/diplom/48.avif";
import diplom48big from "../images/diplom/48big.png";
import diplom48bigwebp from "../images/diplom/48big.webp";
import diplom48bigavif from "../images/diplom/48big.avif";
import diplom49 from "../images/diplom/49.png";
import diplom49webp from "../images/diplom/49.webp";
import diplom49avif from "../images/diplom/49.avif";
import diplom49big from "../images/diplom/49big.png";
import diplom49bigwebp from "../images/diplom/49big.webp";
import diplom49bigavif from "../images/diplom/49big.avif";
import diplom50 from "../images/diplom/50.png";
import diplom50webp from "../images/diplom/50.webp";
import diplom50avif from "../images/diplom/50.avif";
import diplom50big from "../images/diplom/50big.png";
import diplom50bigwebp from "../images/diplom/50big.webp";
import diplom50bigavif from "../images/diplom/50big.avif";
import diplom51 from "../images/diplom/51.png";
import diplom51webp from "../images/diplom/51.webp";
import diplom51avif from "../images/diplom/51.avif";
import diplom51big from "../images/diplom/51big.png";
import diplom51bigwebp from "../images/diplom/51big.webp";
import diplom51bigavif from "../images/diplom/51big.avif";
import diplom52 from "../images/diplom/52.png";
import diplom52webp from "../images/diplom/52.webp";
import diplom52avif from "../images/diplom/52.avif";
import diplom52big from "../images/diplom/52big.png";
import diplom52bigwebp from "../images/diplom/52big.webp";
import diplom52bigavif from "../images/diplom/52big.avif";
import diplom53 from "../images/diplom/53.png";
import diplom53webp from "../images/diplom/53.webp";
import diplom53avif from "../images/diplom/53.avif";
import diplom53big from "../images/diplom/53big.png";
import diplom53bigwebp from "../images/diplom/53big.webp";
import diplom53bigavif from "../images/diplom/53big.avif";
import diplom54 from "../images/diplom/54.png";
import diplom54webp from "../images/diplom/54.webp";
import diplom54avif from "../images/diplom/54.avif";
import diplom54big from "../images/diplom/54big.png";
import diplom54bigwebp from "../images/diplom/54big.webp";
import diplom54bigavif from "../images/diplom/54big.avif";
import diplom55 from "../images/diplom/55.png";
import diplom55webp from "../images/diplom/55.webp";
import diplom55avif from "../images/diplom/55.avif";
import diplom55big from "../images/diplom/55big.png";
import diplom55bigwebp from "../images/diplom/55big.webp";
import diplom55bigavif from "../images/diplom/55big.avif";
import diplom56 from "../images/diplom/56.png";
import diplom56webp from "../images/diplom/56.webp";
import diplom56avif from "../images/diplom/56.avif";
import diplom56big from "../images/diplom/56big.png";
import diplom56bigwebp from "../images/diplom/56big.webp";
import diplom56bigavif from "../images/diplom/56big.avif";
import diplom57 from "../images/diplom/57.png";
import diplom57webp from "../images/diplom/57.webp";
import diplom57avif from "../images/diplom/57.avif";
import diplom57big from "../images/diplom/57big.png";
import diplom57bigwebp from "../images/diplom/57big.webp";
import diplom57bigavif from "../images/diplom/57big.avif";
import diplom58 from "../images/diplom/58.png";
import diplom58webp from "../images/diplom/58.webp";
import diplom58avif from "../images/diplom/58.avif";
import diplom58big from "../images/diplom/58big.png";
import diplom58bigwebp from "../images/diplom/58big.webp";
import diplom58bigavif from "../images/diplom/58big.avif";
import diplom59 from "../images/diplom/59.png";
import diplom59webp from "../images/diplom/59.webp";
import diplom59avif from "../images/diplom/59.avif";
import diplom59big from "../images/diplom/59big.png";
import diplom59bigwebp from "../images/diplom/59big.webp";
import diplom59bigavif from "../images/diplom/59big.avif";
import diplom60 from "../images/diplom/60.png";
import diplom60webp from "../images/diplom/60.webp";
import diplom60avif from "../images/diplom/60.avif";
import diplom60big from "../images/diplom/60big.png";
import diplom60bigwebp from "../images/diplom/60big.webp";
import diplom60bigavif from "../images/diplom/60big.avif";
import diplom61 from "../images/diplom/61.png";
import diplom61webp from "../images/diplom/61.webp";
import diplom61avif from "../images/diplom/61.avif";
import diplom61big from "../images/diplom/61big.png";
import diplom61bigwebp from "../images/diplom/61big.webp";
import diplom61bigavif from "../images/diplom/61big.avif";
import diplom62 from "../images/diplom/62.png";
import diplom62webp from "../images/diplom/62.webp";
import diplom62avif from "../images/diplom/62.avif";
import diplom62big from "../images/diplom/62big.png";
import diplom62bigwebp from "../images/diplom/62big.webp";
import diplom62bigavif from "../images/diplom/62big.avif";
import diplom63 from "../images/diplom/63.png";
import diplom63webp from "../images/diplom/63.webp";
import diplom63avif from "../images/diplom/63.avif";
import diplom63big from "../images/diplom/63big.png";
import diplom63bigwebp from "../images/diplom/63big.webp";
import diplom63bigavif from "../images/diplom/63big.avif";
import diplom64 from "../images/diplom/64.png";
import diplom64webp from "../images/diplom/64.webp";
import diplom64avif from "../images/diplom/64.avif";
import diplom64big from "../images/diplom/64big.png";
import diplom64bigwebp from "../images/diplom/64big.webp";
import diplom64bigavif from "../images/diplom/64big.avif";
import diplom65 from "../images/diplom/65.png";
import diplom65webp from "../images/diplom/65.webp";
import diplom65avif from "../images/diplom/65.avif";
import diplom65big from "../images/diplom/65big.png";
import diplom65bigwebp from "../images/diplom/65big.webp";
import diplom65bigavif from "../images/diplom/65big.avif";
import diplom66 from "../images/diplom/66.png";
import diplom66webp from "../images/diplom/66.webp";
import diplom66avif from "../images/diplom/66.avif";
import diplom66big from "../images/diplom/66big.png";
import diplom66bigwebp from "../images/diplom/66big.webp";
import diplom66bigavif from "../images/diplom/66big.avif";
import diplom67 from "../images/diplom/67.png";
import diplom67webp from "../images/diplom/67.webp";
import diplom67avif from "../images/diplom/67.avif";
import diplom67big from "../images/diplom/67big.png";
import diplom67bigwebp from "../images/diplom/67big.webp";
import diplom67bigavif from "../images/diplom/67big.avif";
import diplom68 from "../images/diplom/68.png";
import diplom68webp from "../images/diplom/68.webp";
import diplom68avif from "../images/diplom/68.avif";
import diplom68big from "../images/diplom/68big.png";
import diplom68bigwebp from "../images/diplom/68big.webp";
import diplom68bigavif from "../images/diplom/68big.avif";
import diplom69 from "../images/diplom/69.png";
import diplom69webp from "../images/diplom/69.webp";
import diplom69avif from "../images/diplom/69.avif";
import diplom69big from "../images/diplom/69big.png";
import diplom69bigwebp from "../images/diplom/69big.webp";
import diplom69bigavif from "../images/diplom/69big.avif";
import diplom70 from "../images/diplom/70.png";
import diplom70webp from "../images/diplom/70.webp";
import diplom70avif from "../images/diplom/70.avif";
import diplom70big from "../images/diplom/70big.png";
import diplom70bigwebp from "../images/diplom/70big.webp";
import diplom70bigavif from "../images/diplom/70big.avif";
import diplom71 from "../images/diplom/71.png";
import diplom71webp from "../images/diplom/71.webp";
import diplom71avif from "../images/diplom/71.avif";
import diplom71big from "../images/diplom/71big.png";
import diplom71bigwebp from "../images/diplom/71big.webp";
import diplom71bigavif from "../images/diplom/71big.avif";
import diplom72 from "../images/diplom/72.png";
import diplom72webp from "../images/diplom/72.webp";
import diplom72avif from "../images/diplom/72.avif";
import diplom72big from "../images/diplom/72big.png";
import diplom72bigwebp from "../images/diplom/72big.webp";
import diplom72bigavif from "../images/diplom/72big.avif";
import diplom73 from "../images/diplom/73.png";
import diplom73webp from "../images/diplom/73.webp";
import diplom73avif from "../images/diplom/73.avif";
import diplom73big from "../images/diplom/73big.png";
import diplom73bigwebp from "../images/diplom/73big.webp";
import diplom73bigavif from "../images/diplom/73big.avif";
import diplom74 from "../images/diplom/74.png";
import diplom74webp from "../images/diplom/74.webp";
import diplom74avif from "../images/diplom/74.avif";
import diplom74big from "../images/diplom/74big.png";
import diplom74bigwebp from "../images/diplom/74big.webp";
import diplom74bigavif from "../images/diplom/74big.avif";
import diplom75 from "../images/diplom/75.png";
import diplom75webp from "../images/diplom/75.webp";
import diplom75avif from "../images/diplom/75.avif";
import diplom75big from "../images/diplom/75big.png";
import diplom75bigwebp from "../images/diplom/75big.webp";
import diplom75bigavif from "../images/diplom/75big.avif";
import diplom76 from "../images/diplom/76.png";
import diplom76webp from "../images/diplom/76.webp";
import diplom76avif from "../images/diplom/76.avif";
import diplom76big from "../images/diplom/76big.png";
import diplom76bigwebp from "../images/diplom/76big.webp";
import diplom76bigavif from "../images/diplom/76big.avif";
import diplom77 from "../images/diplom/77.png";
import diplom77webp from "../images/diplom/77.webp";
import diplom77avif from "../images/diplom/77.avif";
import diplom77big from "../images/diplom/77big.png";
import diplom77bigwebp from "../images/diplom/77big.webp";
import diplom77bigavif from "../images/diplom/77big.avif";
import diplom78 from "../images/diplom/78.png";
import diplom78webp from "../images/diplom/78.webp";
import diplom78avif from "../images/diplom/78.avif";
import diplom78big from "../images/diplom/78big.png";
import diplom78bigwebp from "../images/diplom/78big.webp";
import diplom78bigavif from "../images/diplom/78big.avif";
import diplom79 from "../images/diplom/79.png";
import diplom79webp from "../images/diplom/79.webp";
import diplom79avif from "../images/diplom/79.avif";
import diplom79big from "../images/diplom/79big.png";
import diplom79bigwebp from "../images/diplom/79big.webp";
import diplom79bigavif from "../images/diplom/79big.avif";
import diplom80 from "../images/diplom/80.png";
import diplom80webp from "../images/diplom/80.webp";
import diplom80avif from "../images/diplom/80.avif";
import diplom80big from "../images/diplom/80big.png";
import diplom80bigwebp from "../images/diplom/80big.webp";
import diplom80bigavif from "../images/diplom/80big.avif";

export const DiplomsContext = React.createContext();

export const diploms = [
  {
    key: 1,
    mini: `${diplom37}`,
    big: `${diplom37big}`,
    miniwebp: `${diplom37webp}`,
    miniavif: `${diplom37avif}`,
    bigwebp: `${diplom37bigwebp}`,
    bigavif: `${diplom37bigavif}`,
  },
  {
    key: 2,
    mini: `${diplom38}`,
    big: `${diplom38big}`,
    miniwebp: `${diplom38webp}`,
    miniavif: `${diplom38avif}`,
    bigwebp: `${diplom38bigwebp}`,
    bigavif: `${diplom38bigavif}`,
  },
  {
    key: 3,
    mini: `${diplom39}`,
    big: `${diplom39big}`,
    miniwebp: `${diplom39webp}`,
    miniavif: `${diplom39avif}`,
    bigwebp: `${diplom39bigwebp}`,
    bigavif: `${diplom39bigavif}`,
  },
  {
    key: 4,
    mini: `${diplom40}`,
    big: `${diplom40big}`,
    miniwebp: `${diplom40webp}`,
    miniavif: `${diplom40avif}`,
    bigwebp: `${diplom40bigwebp}`,
    bigavif: `${diplom40bigavif}`,
  },
  {
    key: 5,
    mini: `${diplom5}`,
    big: `${diplom5big}`,
    miniwebp: `${diplom5webp}`,
    miniavif: `${diplom5avif}`,
    bigwebp: `${diplom5bigwebp}`,
    bigavif: `${diplom5bigavif}`,
  },
  {
    key: 6,
    mini: `${diplom6}`,
    big: `${diplom6big}`,
    miniwebp: `${diplom6webp}`,
    miniavif: `${diplom6avif}`,
    bigwebp: `${diplom6bigwebp}`,
    bigavif: `${diplom6bigavif}`,
  },
  {
    key: 7,
    mini: `${diplom7}`,
    big: `${diplom7big}`,
    miniwebp: `${diplom7webp}`,
    miniavif: `${diplom7avif}`,
    bigwebp: `${diplom7bigwebp}`,
    bigavif: `${diplom7bigavif}`,
  },
  {
    key: 8,
    mini: `${diplom8}`,
    big: `${diplom8big}`,
    miniwebp: `${diplom8webp}`,
    miniavif: `${diplom8avif}`,
    bigwebp: `${diplom8bigwebp}`,
    bigavif: `${diplom8bigavif}`,
  },
  {
    key: 9,
    mini: `${diplom9}`,
    big: `${diplom9big}`,
    miniwebp: `${diplom9webp}`,
    miniavif: `${diplom9avif}`,
    bigwebp: `${diplom9bigwebp}`,
    bigavif: `${diplom9bigavif}`,
  },
  {
    key: 10,
    mini: `${diplom10}`,
    big: `${diplom10big}`,
    miniwebp: `${diplom10webp}`,
    miniavif: `${diplom10avif}`,
    bigwebp: `${diplom10bigwebp}`,
    bigavif: `${diplom10bigavif}`,
  },
  {
    key: 11,
    mini: `${diplom11}`,
    big: `${diplom11big}`,
    miniwebp: `${diplom11webp}`,
    miniavif: `${diplom11avif}`,
    bigwebp: `${diplom11bigwebp}`,
    bigavif: `${diplom11bigavif}`,
  },
  {
    key: 12,
    mini: `${diplom12}`,
    big: `${diplom12big}`,
    miniwebp: `${diplom12webp}`,
    miniavif: `${diplom12avif}`,
    bigwebp: `${diplom12bigwebp}`,
    bigavif: `${diplom12bigavif}`,
  },
  {
    key: 13,
    mini: `${diplom13}`,
    big: `${diplom13big}`,
    miniwebp: `${diplom13webp}`,
    miniavif: `${diplom13avif}`,
    bigwebp: `${diplom13bigwebp}`,
    bigavif: `${diplom13bigavif}`,
  },
  {
    key: 14,
    mini: `${diplom14}`,
    big: `${diplom14big}`,
    miniwebp: `${diplom14webp}`,
    miniavif: `${diplom14avif}`,
    bigwebp: `${diplom14bigwebp}`,
    bigavif: `${diplom14bigavif}`,
  },
  {
    key: 15,
    mini: `${diplom15}`,
    big: `${diplom15big}`,
    miniwebp: `${diplom15webp}`,
    miniavif: `${diplom15avif}`,
    bigwebp: `${diplom15bigwebp}`,
    bigavif: `${diplom15bigavif}`,
  },
  {
    key: 16,
    mini: `${diplom16}`,
    big: `${diplom16big}`,
    miniwebp: `${diplom16webp}`,
    miniavif: `${diplom16avif}`,
    bigwebp: `${diplom16bigwebp}`,
    bigavif: `${diplom16bigavif}`,
  },
  {
    key: 17,
    mini: `${diplom17}`,
    big: `${diplom17big}`,
    miniwebp: `${diplom17webp}`,
    miniavif: `${diplom17avif}`,
    bigwebp: `${diplom17bigwebp}`,
    bigavif: `${diplom17bigavif}`,
  },
  {
    key: 18,
    mini: `${diplom18}`,
    big: `${diplom18big}`,
    miniwebp: `${diplom18webp}`,
    miniavif: `${diplom18avif}`,
    bigwebp: `${diplom18bigwebp}`,
    bigavif: `${diplom18bigavif}`,
  },
  {
    key: 19,
    mini: `${diplom19}`,
    big: `${diplom19big}`,
    miniwebp: `${diplom19webp}`,
    miniavif: `${diplom19avif}`,
    bigwebp: `${diplom19bigwebp}`,
    bigavif: `${diplom19bigavif}`,
  },
  {
    key: 20,
    mini: `${diplom20}`,
    big: `${diplom20big}`,
    miniwebp: `${diplom20webp}`,
    miniavif: `${diplom20avif}`,
    bigwebp: `${diplom20bigwebp}`,
    bigavif: `${diplom20bigavif}`,
  },
  {
    key: 21,
    mini: `${diplom21}`,
    big: `${diplom21big}`,
    miniwebp: `${diplom21webp}`,
    miniavif: `${diplom21avif}`,
    bigwebp: `${diplom21bigwebp}`,
    bigavif: `${diplom21bigavif}`,
  },
  {
    key: 22,
    mini: `${diplom22}`,
    big: `${diplom22big}`,
    miniwebp: `${diplom22webp}`,
    miniavif: `${diplom22avif}`,
    bigwebp: `${diplom22bigwebp}`,
    bigavif: `${diplom22bigavif}`,
  },
  {
    key: 23,
    mini: `${diplom23}`,
    big: `${diplom23big}`,
    miniwebp: `${diplom23webp}`,
    miniavif: `${diplom23avif}`,
    bigwebp: `${diplom23bigwebp}`,
    bigavif: `${diplom23bigavif}`,
  },
  {
    key: 24,
    mini: `${diplom24}`,
    big: `${diplom24big}`,
    miniwebp: `${diplom24webp}`,
    miniavif: `${diplom24avif}`,
    bigwebp: `${diplom24bigwebp}`,
    bigavif: `${diplom24bigavif}`,
  },
  {
    key: 25,
    mini: `${diplom25}`,
    big: `${diplom25big}`,
    miniwebp: `${diplom25webp}`,
    miniavif: `${diplom25avif}`,
    bigwebp: `${diplom25bigwebp}`,
    bigavif: `${diplom25bigavif}`,
  },
  {
    key: 26,
    mini: `${diplom26}`,
    big: `${diplom26big}`,
    miniwebp: `${diplom26webp}`,
    miniavif: `${diplom26avif}`,
    bigwebp: `${diplom26bigwebp}`,
    bigavif: `${diplom26bigavif}`,
  },
  {
    key: 27,
    mini: `${diplom27}`,
    big: `${diplom27big}`,
    miniwebp: `${diplom27webp}`,
    miniavif: `${diplom27avif}`,
    bigwebp: `${diplom27bigwebp}`,
    bigavif: `${diplom27bigavif}`,
  },
  {
    key: 28,
    mini: `${diplom28}`,
    big: `${diplom28big}`,
    miniwebp: `${diplom28webp}`,
    miniavif: `${diplom28avif}`,
    bigwebp: `${diplom28bigwebp}`,
    bigavif: `${diplom28bigavif}`,
  },
  {
    key: 29,
    mini: `${diplom29}`,
    big: `${diplom29big}`,
    miniwebp: `${diplom29webp}`,
    miniavif: `${diplom29avif}`,
    bigwebp: `${diplom29bigwebp}`,
    bigavif: `${diplom29bigavif}`,
  },
  {
    key: 30,
    mini: `${diplom30}`,
    big: `${diplom30big}`,
    miniwebp: `${diplom30webp}`,
    miniavif: `${diplom30avif}`,
    bigwebp: `${diplom30bigwebp}`,
    bigavif: `${diplom30bigavif}`,
  },
  {
    key: 31,
    mini: `${diplom31}`,
    big: `${diplom31big}`,
    miniwebp: `${diplom31webp}`,
    miniavif: `${diplom31avif}`,
    bigwebp: `${diplom31bigwebp}`,
    bigavif: `${diplom31bigavif}`,
  },
  {
    key: 32,
    mini: `${diplom32}`,
    big: `${diplom32big}`,
    miniwebp: `${diplom32webp}`,
    miniavif: `${diplom32avif}`,
    bigwebp: `${diplom32bigwebp}`,
    bigavif: `${diplom32bigavif}`,
  },
  {
    key: 33,
    mini: `${diplom33}`,
    big: `${diplom33big}`,
    miniwebp: `${diplom33webp}`,
    miniavif: `${diplom33avif}`,
    bigwebp: `${diplom33bigwebp}`,
    bigavif: `${diplom33bigavif}`,
  },
  {
    key: 34,
    mini: `${diplom34}`,
    big: `${diplom34big}`,
    miniwebp: `${diplom34webp}`,
    miniavif: `${diplom34avif}`,
    bigwebp: `${diplom34bigwebp}`,
    bigavif: `${diplom34bigavif}`,
  },
  {
    key: 35,
    mini: `${diplom35}`,
    big: `${diplom35big}`,
    miniwebp: `${diplom35webp}`,
    miniavif: `${diplom35avif}`,
    bigwebp: `${diplom35bigwebp}`,
    bigavif: `${diplom35bigavif}`,
  },
  {
    key: 36,
    mini: `${diplom36}`,
    big: `${diplom36big}`,
    miniwebp: `${diplom36webp}`,
    miniavif: `${diplom36avif}`,
    bigwebp: `${diplom36bigwebp}`,
    bigavif: `${diplom36bigavif}`,
  },
  {
    key: 37,
    mini: `${diplom1}`,
    big: `${diplom1big}`,
    miniwebp: `${diplom1webp}`,
    miniavif: `${diplom1avif}`,
    bigwebp: `${diplom1bigwebp}`,
    bigavif: `${diplom1bigavif}`,
  },
  {
    key: 38,
    mini: `${diplom2}`,
    big: `${diplom2big}`,
    miniwebp: `${diplom2webp}`,
    miniavif: `${diplom2avif}`,
    bigwebp: `${diplom2bigwebp}`,
    bigavif: `${diplom2bigavif}`,
  },
  {
    key: 39,
    mini: `${diplom3}`,
    big: `${diplom3big}`,
    miniwebp: `${diplom3webp}`,
    miniavif: `${diplom3avif}`,
    bigwebp: `${diplom3bigwebp}`,
    bigavif: `${diplom3bigavif}`,
  },
  {
    key: 40,
    mini: `${diplom4}`,
    big: `${diplom4big}`,
    miniwebp: `${diplom4webp}`,
    miniavif: `${diplom4avif}`,
    bigwebp: `${diplom4bigwebp}`,
    bigavif: `${diplom4bigavif}`,
  },
  {
    key: 41,
    mini: `${diplom41}`,
    big: `${diplom41big}`,
    miniwebp: `${diplom41webp}`,
    miniavif: `${diplom41avif}`,
    bigwebp: `${diplom41bigwebp}`,
    bigavif: `${diplom41bigavif}`,
  },
  {
    key: 42,
    mini: `${diplom42}`,
    big: `${diplom42big}`,
    miniwebp: `${diplom42webp}`,
    miniavif: `${diplom42avif}`,
    bigwebp: `${diplom42bigwebp}`,
    bigavif: `${diplom42bigavif}`,
  },
  {
    key: 43,
    mini: `${diplom43}`,
    big: `${diplom43big}`,
    miniwebp: `${diplom43webp}`,
    miniavif: `${diplom43avif}`,
    bigwebp: `${diplom43bigwebp}`,
    bigavif: `${diplom43bigavif}`,
  },
  {
    key: 44,
    mini: `${diplom44}`,
    big: `${diplom44big}`,
    miniwebp: `${diplom44webp}`,
    miniavif: `${diplom44avif}`,
    bigwebp: `${diplom44bigwebp}`,
    bigavif: `${diplom44bigavif}`,
  },
  {
    key: 45,
    mini: `${diplom45}`,
    big: `${diplom45big}`,
    miniwebp: `${diplom45webp}`,
    miniavif: `${diplom45avif}`,
    bigwebp: `${diplom45bigwebp}`,
    bigavif: `${diplom45bigavif}`,
  },
  {
    key: 46,
    mini: `${diplom46}`,
    big: `${diplom46big}`,
    miniwebp: `${diplom46webp}`,
    miniavif: `${diplom46avif}`,
    bigwebp: `${diplom46bigwebp}`,
    bigavif: `${diplom46bigavif}`,
  },
  {
    key: 47,
    mini: `${diplom47}`,
    big: `${diplom47big}`,
    miniwebp: `${diplom47webp}`,
    miniavif: `${diplom47avif}`,
    bigwebp: `${diplom47bigwebp}`,
    bigavif: `${diplom47bigavif}`,
  },
  {
    key: 48,
    mini: `${diplom48}`,
    big: `${diplom48big}`,
    miniwebp: `${diplom48webp}`,
    miniavif: `${diplom48avif}`,
    bigwebp: `${diplom48bigwebp}`,
    bigavif: `${diplom48bigavif}`,
  },
  {
    key: 49,
    mini: `${diplom49}`,
    big: `${diplom49big}`,
    miniwebp: `${diplom49webp}`,
    miniavif: `${diplom49avif}`,
    bigwebp: `${diplom49bigwebp}`,
    bigavif: `${diplom49bigavif}`,
  },
  {
    key: 50,
    mini: `${diplom50}`,
    big: `${diplom50big}`,
    miniwebp: `${diplom50webp}`,
    miniavif: `${diplom50avif}`,
    bigwebp: `${diplom50bigwebp}`,
    bigavif: `${diplom50bigavif}`,
  },
  {
    key: 51,
    mini: `${diplom51}`,
    big: `${diplom51big}`,
    miniwebp: `${diplom51webp}`,
    miniavif: `${diplom51avif}`,
    bigwebp: `${diplom51bigwebp}`,
    bigavif: `${diplom51bigavif}`,
  },
  {
    key: 52,
    mini: `${diplom52}`,
    big: `${diplom52big}`,
    miniwebp: `${diplom52webp}`,
    miniavif: `${diplom52avif}`,
    bigwebp: `${diplom52bigwebp}`,
    bigavif: `${diplom52bigavif}`,
  },
  {
    key: 53,
    mini: `${diplom53}`,
    big: `${diplom53big}`,
    miniwebp: `${diplom53webp}`,
    miniavif: `${diplom53avif}`,
    bigwebp: `${diplom53bigwebp}`,
    bigavif: `${diplom53bigavif}`,
  },
  {
    key: 54,
    mini: `${diplom54}`,
    big: `${diplom54big}`,
    miniwebp: `${diplom54webp}`,
    miniavif: `${diplom54avif}`,
    bigwebp: `${diplom54bigwebp}`,
    bigavif: `${diplom54bigavif}`,
  },
  {
    key: 55,
    mini: `${diplom55}`,
    big: `${diplom55big}`,
    miniwebp: `${diplom55webp}`,
    miniavif: `${diplom55avif}`,
    bigwebp: `${diplom55bigwebp}`,
    bigavif: `${diplom55bigavif}`,
  },
  {
    key: 56,
    mini: `${diplom56}`,
    big: `${diplom56big}`,
    miniwebp: `${diplom56webp}`,
    miniavif: `${diplom56avif}`,
    bigwebp: `${diplom56bigwebp}`,
    bigavif: `${diplom56bigavif}`,
  },
  {
    key: 57,
    mini: `${diplom57}`,
    big: `${diplom57big}`,
    miniwebp: `${diplom57webp}`,
    miniavif: `${diplom57avif}`,
    bigwebp: `${diplom57bigwebp}`,
    bigavif: `${diplom57bigavif}`,
  },
  {
    key: 58,
    mini: `${diplom58}`,
    big: `${diplom58big}`,
    miniwebp: `${diplom58webp}`,
    miniavif: `${diplom58avif}`,
    bigwebp: `${diplom58bigwebp}`,
    bigavif: `${diplom58bigavif}`,
  },
  {
    key: 59,
    mini: `${diplom59}`,
    big: `${diplom59big}`,
    miniwebp: `${diplom59webp}`,
    miniavif: `${diplom59avif}`,
    bigwebp: `${diplom59bigwebp}`,
    bigavif: `${diplom59bigavif}`,
  },
  {
    key: 60,
    mini: `${diplom60}`,
    big: `${diplom60big}`,
    miniwebp: `${diplom60webp}`,
    miniavif: `${diplom60avif}`,
    bigwebp: `${diplom60bigwebp}`,
    bigavif: `${diplom60bigavif}`,
  },
  {
    key: 61,
    mini: `${diplom61}`,
    big: `${diplom61big}`,
    miniwebp: `${diplom61webp}`,
    miniavif: `${diplom61avif}`,
    bigwebp: `${diplom61bigwebp}`,
    bigavif: `${diplom61bigavif}`,
  },
  {
    key: 62,
    mini: `${diplom62}`,
    big: `${diplom62big}`,
    miniwebp: `${diplom62webp}`,
    miniavif: `${diplom62avif}`,
    bigwebp: `${diplom62bigwebp}`,
    bigavif: `${diplom62bigavif}`,
  },
  {
    key: 63,
    mini: `${diplom63}`,
    big: `${diplom63big}`,
    miniwebp: `${diplom63webp}`,
    miniavif: `${diplom63avif}`,
    bigwebp: `${diplom63bigwebp}`,
    bigavif: `${diplom63bigavif}`,
  },
  {
    key: 64,
    mini: `${diplom64}`,
    big: `${diplom64big}`,
    miniwebp: `${diplom64webp}`,
    miniavif: `${diplom64avif}`,
    bigwebp: `${diplom64bigwebp}`,
    bigavif: `${diplom64bigavif}`,
  },
  {
    key: 65,
    mini: `${diplom65}`,
    big: `${diplom65big}`,
    miniwebp: `${diplom65webp}`,
    miniavif: `${diplom65avif}`,
    bigwebp: `${diplom65bigwebp}`,
    bigavif: `${diplom65bigavif}`,
  },
  {
    key: 66,
    mini: `${diplom66}`,
    big: `${diplom66big}`,
    miniwebp: `${diplom66webp}`,
    miniavif: `${diplom66avif}`,
    bigwebp: `${diplom66bigwebp}`,
    bigavif: `${diplom66bigavif}`,
  },
  {
    key: 67,
    mini: `${diplom67}`,
    big: `${diplom67big}`,
    miniwebp: `${diplom67webp}`,
    miniavif: `${diplom67avif}`,
    bigwebp: `${diplom67bigwebp}`,
    bigavif: `${diplom67bigavif}`,
  },
  {
    key: 68,
    mini: `${diplom68}`,
    big: `${diplom68big}`,
    miniwebp: `${diplom68webp}`,
    miniavif: `${diplom68avif}`,
    bigwebp: `${diplom68bigwebp}`,
    bigavif: `${diplom68bigavif}`,
  },
  {
    key: 69,
    mini: `${diplom69}`,
    big: `${diplom69big}`,
    miniwebp: `${diplom69webp}`,
    miniavif: `${diplom69avif}`,
    bigwebp: `${diplom69bigwebp}`,
    bigavif: `${diplom69bigavif}`,
  },
  {
    key: 70,
    mini: `${diplom70}`,
    big: `${diplom70big}`,
    miniwebp: `${diplom70webp}`,
    miniavif: `${diplom70avif}`,
    bigwebp: `${diplom70bigwebp}`,
    bigavif: `${diplom70bigavif}`,
  },
  {
    key: 71,
    mini: `${diplom71}`,
    big: `${diplom71big}`,
    miniwebp: `${diplom71webp}`,
    miniavif: `${diplom71avif}`,
    bigwebp: `${diplom71bigwebp}`,
    bigavif: `${diplom71bigavif}`,
  },
  {
    key: 72,
    mini: `${diplom72}`,
    big: `${diplom72big}`,
    miniwebp: `${diplom72webp}`,
    miniavif: `${diplom72avif}`,
    bigwebp: `${diplom72bigwebp}`,
    bigavif: `${diplom72bigavif}`,
  },
  {
    key: 73,
    mini: `${diplom73}`,
    big: `${diplom73big}`,
    miniwebp: `${diplom73webp}`,
    miniavif: `${diplom73avif}`,
    bigwebp: `${diplom73bigwebp}`,
    bigavif: `${diplom73bigavif}`,
  },
  {
    key: 74,
    mini: `${diplom74}`,
    big: `${diplom74big}`,
    miniwebp: `${diplom74webp}`,
    miniavif: `${diplom74avif}`,
    bigwebp: `${diplom74bigwebp}`,
    bigavif: `${diplom74bigavif}`,
  },
  {
    key: 75,
    mini: `${diplom75}`,
    big: `${diplom75big}`,
    miniwebp: `${diplom75webp}`,
    miniavif: `${diplom75avif}`,
    bigwebp: `${diplom75bigwebp}`,
    bigavif: `${diplom75bigavif}`,
  },
  {
    key: 76,
    mini: `${diplom76}`,
    big: `${diplom76big}`,
    miniwebp: `${diplom76webp}`,
    miniavif: `${diplom76avif}`,
    bigwebp: `${diplom76bigwebp}`,
    bigavif: `${diplom76bigavif}`,
  },
  {
    key: 77,
    mini: `${diplom77}`,
    big: `${diplom77big}`,
    miniwebp: `${diplom77webp}`,
    miniavif: `${diplom77avif}`,
    bigwebp: `${diplom77bigwebp}`,
    bigavif: `${diplom77bigavif}`,
  },
  {
    key: 78,
    mini: `${diplom78}`,
    big: `${diplom78big}`,
    miniwebp: `${diplom78webp}`,
    miniavif: `${diplom78avif}`,
    bigwebp: `${diplom78bigwebp}`,
    bigavif: `${diplom78bigavif}`,
  },
  {
    key: 79,
    mini: `${diplom79}`,
    big: `${diplom79big}`,
    miniwebp: `${diplom79webp}`,
    miniavif: `${diplom79avif}`,
    bigwebp: `${diplom79bigwebp}`,
    bigavif: `${diplom79bigavif}`,
  },
  {
    key: 80,
    mini: `${diplom80}`,
    big: `${diplom80big}`,
    miniwebp: `${diplom80webp}`,
    miniavif: `${diplom80avif}`,
    bigwebp: `${diplom80bigwebp}`,
    bigavif: `${diplom80bigavif}`,
  },
];
