import "./Thanks.css";
import { useNavigate } from "react-router-dom";
import ok from "../../images/icon/check-mark.png";
import okwebp from "../../images/icon/check-mark.webp";
import okavif from "../../images/icon/check-mark.avif";

function Thanks() {
  window.ym(94934592, "hit", "/thank-you");
  const history = useNavigate();
  function onClose() {
    history("/");
  }
  return (
    <section
      className="thanks"
      onClick={(e) => e.currentTarget === e.target && onClose()}
    >
      <div className="thanks__box">
        <button
          className="thanks__close"
          type="button"
          onClick={onClose}
        ></button>
        <picture>
          <source srcSet={okwebp} type="image/webp" />
          <source srcSet={okavif} type="image/avif" />
          <source srcSet={ok} type="image/png" />
          <img className="thanks__img" src={ok} alt="OK" type="image/png" />
        </picture>
        <h2 className="thanks__title">
          <span className="thanks__span">Спасибо за заявку,</span> мы свяжемся с
          Вами в ближайшее время!
        </h2>
        <p className="thanks__text">Наши контакты:</p>

        <div className="thanks__box-mess">
          <a
            className="thanks__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/79871856569?text=Здравствуйте.+Хочу+записать+своего+ребенка+на+занятие"
          >
            {null}
          </a>
          <a
            className="thanks__social-link"
            target="_blank"
            rel="noreferrer"
            href="viber://chat?number=%2B79871856569"
          >
            {null}
          </a>
          <a
            className="thanks__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/perevorot_dance"
          >
            {null}
          </a>
          <a
            className="thanks__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://vk.com/perevorot_dance"
          >
            {null}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Thanks;
