import "./Oferta.css";
import React from "react";

function Oferta() {
  return (
    <section className="oferta">
      <h2 className="section__title">ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ</h2>
      <h3 className="oferta__title">НА ОБУЧЕНИЕ В ТЕАТРЕ ТАНЦА «ПЕРЕВОРОТ»</h3>
      <p className="oferta__text">
        Настоящий договор является официальной публичной офертой Театра танца
        «Переворот», действующего на основании записи ЕГРИП 318344300036913, в
        лице руководителя Иконникова Алексея Анатольевича, именуемого в
        дальнейшем «Исполнитель», и законным представителем несовершеннолетнего
        ребенка, именуемым в дальнейшем «Заказчик».
      </p>
      <p className="oferta__text">
        Настоящий договор признаётся заключенным с момента его акцепта
        Заказчиком. Под акцептом в целях настоящего договора признаётся
        подписание данного договора и передача денежных средств в соответствии
        установленной платой исполнителем на оказываемые услуги. Таким образом,
        данное предложение («Договор оферты») является офертой, а его оплата –
        акцептом. Такой порядок заключения сделки признается соблюдением
        письменной формы договора.
      </p>
      <h3 className="oferta__title">1. ПРЕДМЕТ ДОГОВОРА</h3>
      <p className="oferta__text">
        1.1. Исполнитель обязуется оказывать услуги по обучению ребенка танцам с
        элементами акробатики в условиях частного обучения в соответствии с
        условиями настоящего договора.
      </p>
      <p className="oferta__text">
        1.2. Занятия проводятся два раза в неделю по одному часу по следующим
        направлениям: современный эстрадный танец, классический танец, ритмика,
        джаз-модерн, контемпорари, современная хореография.
      </p>
      <p className="oferta__text">
        1.3. Исполнитель обязуется проводить ежегодные отчетные концерты.
      </p>
      <p className="oferta__text">
        1.4. Заказчик обязуется, в полном объеме соблюдать правила,
        установленные настоящим договором и в соответствии с условиями договора,
        оплачивать оказываемые услуги.
      </p>
      <p className="oferta__text">
        1.5. В соответствии с п. 2 ст. 437 Гражданского Кодекса РФ в случае
        принятия изложенных ниже условий и оплаты услуг, физическое лицо,
        производящее акцепт этой оферты становится Заказчиком (в соответствии с
        п. 3 ст. 438 Гражданского Кодекса РФ акцепт оферты равносилен заключению
        договора на условиях, изложенных в оферте), а Исполнитель и Заказчик
        совместно – Сторонами Договора оферты.
      </p>
      <p className="oferta__text">
        1.6. Публичная Оферта является официальным документом и публикуется на
        сайте Танцевальной академии по адресу: www.perevorotdance.com.
      </p>
      <p className="oferta__text">
        1.7. Обучение осуществляется по адресам, указанным на сайте
        www.perevorotdance.com.
      </p>
      <p className="oferta__text">
        1.8. Указанные услуги не являются образовательными и не подлежат
        лицензированию в соответствии с положениями Федерального закона от 29
        декабря 2012 г. № 273-ФЗ "Об образовании в Российской Федерации".
      </p>
      <h3 className="oferta__title">2. ОБЯЗАННОСТИ «ТЕАТРА ТАНЦА ПЕРЕВОРОТ»</h3>
      <p className="oferta__text">
        2.1. Надлежащим образом организовывать и осуществлять обучение ребенка
        танцам.
      </p>
      <p className="oferta__text">
        2.2. Обеспечить охрану жизни и укрепление физического и психического
        здоровья ребенка: его физическое и личностное развитие. Развитие его
        творческих способностей и интересов. Осуществлять индивидуальный и
        авторский подход к ребенку, учитывая особенности его развития,
        заботиться об эмоциональном благополучии ребенка.
      </p>
      <p className="oferta__text">
        2.3. Занятия проводятся два раза в неделю по одному часу по следующим
        направлениям: современный эстрадный танец, классический танец, ритмика,
        джаз-модерн, контемпорари, современная хореография.
      </p>
      <p className="oferta__text">
        2.4. Обеспечить помещением (студией) для занятий танцами, отвечающим
        санитарно-гигиеническим требованиям.
      </p>
      <p className="oferta__text">
        2.5. Организовать обучение ребенка в соответствии с его возрастом,
        индивидуальными особенностями.
      </p>
      <p className="oferta__text">
        2.6. Сохранять место за ребенком с условием оплаты «Заказчиком» за дни
        отсутствия в полном размере.
      </p>
      <p className="oferta__text">
        2.7. Вести учет оказываемых Заказчику Услуг с помощью внутренней системы
        учета театра танца «Переворот».
      </p>
      <p className="oferta__text">
        2.8. Обеспечить обучение ребенка квалифицированными
        хореографами-постановщиками.
      </p>
      <h3 className="oferta__title">3. ОБЯЗАННОСТИ «ЗАКАЗЧИКА»</h3>
      <p className="oferta__text">
        3.1. Ознакомиться с правилами и соблюдать правила пребывания в театре
        танца «Переворот» согласно Приложения № 1 и условия настоящего договора.
      </p>
      <p className="oferta__text">
        3.2. Соблюдать санитарно-гигиенические требования по пребыванию ребенка
        в соответствующей возрастной группе.
      </p>
      <p className="oferta__text">
        3.3. За свой счет обеспечивать ребенка концертными костюмами для участия
        в отчетных концертах.
      </p>
      <p className="oferta__text">
        3.4. Заблаговременно информировать Театр танца о предстоящем отсутствии
        ребенка, извещать о болезни ребенка. В случае инфекционного заболевания,
        представлять справку от врача о возможности пребывания ребенка в детском
        учреждении.
      </p>
      <p className="oferta__text">
        3.5. Своевременно производить оплату услуг, оказываемых «Театром танца
        Переворот» в соответствии с п. 6.1. и 6.2. данного договора.
      </p>
      <p className="oferta__text">
        3.6. Самостоятельно нести ответственность за состояние здоровья ребенка.
        Перед началом посещения занятий обязуется ознакомиться с имеющимися
        ограничениями, проконсультироваться у медицинского работника о возможных
        противопоказаниях, самостоятельно контролировать физическую нагрузку,
        при необходимости – поставить в известность хореографа-преподавателя.
      </p>
      <p className="oferta__text">
        3.7. Законный представитель несовершеннолетнего ребенка должен
        находиться на территории студии в течение всего времени нахождения
        ребенка в хореографическом зале.
      </p>
      <h3 className="oferta__title">4. ПРАВА «ТЕАТРА ТАНЦА ПЕРЕВОРОТ»</h3>
      <p className="oferta__text">
        4.1. Пересматривать ежемесячную стоимость услуг в силу изменения
        экономических условий, с предварительным уведомлением, но не позже чем
        за 30 календарных дней до введения изменений.
      </p>
      <p className="oferta__text">
        4.2. Расторгнуть договор в одностороннем порядке без предварительного
        уведомления при наличии медицинского заключения о состоянии здоровья
        ребенка, не совместимом с занятиями танцами, сокрытии карантина в семье
        или по месту проживания ребенка.
      </p>
      <p className="oferta__text">
        4.3. Использовать фото и видео материалы в рекламных целях исключительно
        «Театра танца Переворот», а так же для предоставления отчёта о
        проводимых занятиях заказчику.
      </p>
      <p className="oferta__text">
        4.4. В соответствии с ч. 1 ст. 152.1 Гражданского Кодекса РФ заказчик
        даёт право исполнителю на обнародование и дальнейшее использование
        изображения (в том числе его фотографии с занятий, отчётных концертов и
        прочих, полученных при исполнении настоящего договора исполнителем, а
        также видеозаписей или произведения изобразительного искусства, в
        которых изображен он либо ребенок заказчика).
      </p>
      <p className="oferta__text">
        4.5. Расторгнуть настоящий договор досрочно в случае невыполнении
        заказчиком условий настоящего договора, правил пребывания в Театре танца
        «Переворот», а также расторгнуть действие данного договора в
        одностороннем внесудебном порядке без обоснования причин.
      </p>
      <h3 className="oferta__title">5. ПРАВА «ЗАКАЗЧИКА»</h3>
      <p className="oferta__text">
        5.1. Требовать от Исполнителя соблюдения условий настоящего договора.
      </p>
      <p className="oferta__text">
        5.2. Принимать участие в отчетных концертах.
      </p>
      <p className="oferta__text">
        5.3. Расторгнуть настоящий договор досрочно путём письменного
        уведомления любым удобным способом в адрес исполнителя.
      </p>
      <p className="oferta__text">
        5.4. Присутствовать на открытых занятиях в Театре танца «Переворот».
      </p>
      <p className="oferta__text">
        5.5. В течение действия абонемента при предоставлении справки о болезни
        при пропуске от половины занятий и более заказчик имеет право на
        перерасчёт и перенос оплаты по абонементу на следующий месяц продлить
        абонемент на количество пропущенных занятий и посещать их в соответствии
        с расписанием. По согласованию с хореографом, чтобы скомпенсировать
        пропущенные занятия, заказчик имеет право посетить дополнительные
        занятия в группе аналогичного направления.
      </p>
      <p className="oferta__text">
        5.6. Требовать отчётные документы по оказанным услугам путем письменного
        обращения к Исполнителю.
      </p>
      <h3 className="oferta__title">6. УСЛОВИЯ ОПЛАТЫ</h3>
      <p className="oferta__text">
        6.1. Оплата услуг по настоящему договору составляет на территории
        Республики Татарстан 2 500 рублей в месяц, первое разовое занятие 300
        рублей, на территории Москвы и Московской области 5 000 рублей, первое
        разовое занятие 500 рублей.
      </p>
      <p className="oferta__text">
        6.2. Предоплата 100 %. Оплата производится ежемесячно, на первом занятии
        нового месяца.
      </p>
      <p className="oferta__text">
        6.3. При отсутствии ребенка на всех занятиях, внесенная оплата
        возвращается в размере 90 % от общей суммы договора. В случае посещения
        ребенком хотя бы одного занятия в текущем месяце, оплата не
        возвращается.
      </p>
      <p className="oferta__text">
        6.4. В случае невнесения суммы установленного платежа до трёх занятий
        текущего месяца, договор считается прекращенным по инициативе заказчика
        автоматически.
      </p>
      <p className="oferta__text">
        6.5. Оплата занятий производится любым удобным заказчиком образом
        (наличный, безналичный расчёт, перевод денежных средств администратору
        на банковскую карту).
      </p>
      <p className="oferta__text">
        6.6. Обучение представляет систему оплаты, в которую входит 8 занятий,
        при этом Исполнитель в зависимости от календарного месяца года проводит
        бонусное бесплатное 9 занятие, которое компенсирует занятия, выпадающие
        на государственные праздничные и выходные дни.
      </p>
      <h3 className="oferta__title">7. СРОК ДЕЙСТВИЯ ДОГОВОРА</h3>
      <p className="oferta__text">
        7.1. Договор считается заключенным с момента оплаты и начала оказания
        услуг.
      </p>
      <p className="oferta__text">
        7.2. Договор, может быть изменен, дополнен и расторгнут в любое время по
        соглашению сторон.
      </p>
      <p className="oferta__text">
        7.3. Изменения и дополнения к договору оформляются в письменной форме в
        виде дополнительного соглашения и подписываются двумя сторонами.
      </p>
      <h3 className="oferta__title">8. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
      <p className="oferta__text">
        8.1. В случае неисполнения или ненадлежащего исполнения сторонами
        обязательств по настоящему договору, они несут ответственность,
        предусмотренную действующим законодательством РФ.
      </p>
      <p className="oferta__text">
        8.2. В вопросах, не урегулированных настоящим договором, стороны
        руководствуются действующим законодательством РФ.
      </p>
      <p className="oferta__text">
        8.3. Заказчик обязан направить досудебную претензия исполнителю по
        электронной почте perevorotdance@gmail.com, сроки рассмотрения которой
        составляют 10 дней.
      </p>
      <p className="oferta__text">
        8.4. В случае невозможности урегулирования споров и разногласий путем
        переговоров, Стороны руководствуются нормами ст. 29-32 ГПК РФ, по
        собственной воле определили подсудность исключительно по месту
        фактического нахождения Исполнителя.
      </p>
      <p className="oferta__text">
        8.5. Настоящий договор имеет равную юридическую силу подписанному
        договору.
      </p>
      <p className="oferta__text">
        8.6. Все претензии к Исполнителю должны иметь обязательную письменную
        форму.
      </p>
      <h3 className="oferta__title">9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p className="oferta__text">
        9.1. Заключением настоящего договора 3аказчик подтверждает ознакомление
        с политикой конденфенциальности Театра танца «Переворот», на сайте
        www.perevorotdance.com
      </p>
      <p className="oferta__text">
        9.2. Заключением настоящего договора Заказчик подтверждает ознакомление
        с правилами посещения студии Театра танца «Переворот», правилами
        безопасности и поведения.
      </p>
      <p className="oferta__text">
        9.3. Заказчик согласен на обработку и использование своих персональных
        данных в рамках исполнения Договора, а также на получение рекламы и
        информации путем SMS сообщений.
      </p>
      <h3 className="oferta__title">10. РЕКВИЗИТЫ ТЕАТРА ТАНЦА «ПЕРЕВОРОТ»</h3>
      <p className="oferta__text">
        ИНН 344553401661, ОГРНИП 318344300036913, расчётный счёт
        №40802810200000647583 в АО «Тинькофф Банк», БИК 044525974,
        корреспондентский счёт 30101810145250000974, ИНН банка 7710140679, адрес
        для направления претензий и предложений perevorotdance@gmail.com,
        телефон: 8(800)2016762, 89871856569.
      </p>
    </section>
  );
}

export default Oferta;
