import React from "react";
import comanda1 from "../images/comanda/1.png";
import comanda1webp from "../images/comanda/1.webp";
import comanda1avif from "../images/comanda/1.avif";
import comanda2 from "../images/comanda/2.png";
import comanda2webp from "../images/comanda/2.webp";
import comanda2avif from "../images/comanda/2.avif";
import comanda3 from "../images/comanda/3.png";
import comanda3webp from "../images/comanda/3.webp";
import comanda3avif from "../images/comanda/3.avif";
import comanda4 from "../images/comanda/4.png";
import comanda4webp from "../images/comanda/4.webp";
import comanda4avif from "../images/comanda/4.avif";
import comanda5 from "../images/comanda/5.png";
import comanda5webp from "../images/comanda/5.webp";
import comanda5avif from "../images/comanda/5.avif";
import comanda6 from "../images/comanda/6.png";
import comanda6webp from "../images/comanda/6.webp";
import comanda6avif from "../images/comanda/6.avif";
import comanda7 from "../images/comanda/7.png";
import comanda7webp from "../images/comanda/7.webp";
import comanda7avif from "../images/comanda/7.avif";

export const ComandaContext = React.createContext();

export const comanda = [
  {
    key: 1,
    src: `${comanda1}`,
    webp: `${comanda1webp}`,
    avif: `${comanda1avif}`,
    name: "Юлия Федоровна",
    work: "Хореограф-постановщик, создатель театра танца «Переворот»",
    text: [
      "В детстве занималась художественной гимнастикой имеет звание Кандидата Мастера Спорта, параллельно занималась хореографией, где также достигла высокие результатов, на Всероссийских, Республиканских и Международных Фестивалях и Конкурсах.",
      "Опыт работы с детьми составляет 9 лет.",
      "Разработала свою собственную авторскую методику для занятий с детьми от 3 лет. С 2010 года коллектив Юлии Фёдоровны становился лауреатами на различных Фестивалях и Конкурсах.",
      "Также Юлия Федоровна постоянно улучшает свои творческие навыки в хореографии.",
      "Родители с благодарностью относятся к преподавателю за ее кропотливый, нелегкий труд, терпение, желание приобщить детей к искусству, научить легко и красиво танцевать.",
    ],
  },
  {
    key: 2,
    src: `${comanda6}`,
    webp: `${comanda6webp}`,
    avif: `${comanda6avif}`,
    name: "Тухфятуллова Алина",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "Окончила Казанский государственный институт культуры факультет «Высшая школа искусств», кафедру хореографического искусства в 2022 году с красным дипломом.",
      "Участница и экс-солистка Народного коллектива ансамбля танца «Дети Солнца» (2006-2018) г. Ульяновск.",
      "Алина Мунировна является неоднократным Лауреатом Гран-при, Лауреатом I степени Международных конкурсов и фестивалей.",
      "Участница и постановщик танцевального проекта «Contemporary Play” ( 2020-2022).",
      "Участница церемоний открытия и закрытия Международных спортивных состязаний.",
      "Участница студенческой балетной труппы КазГИК, представляющей русский балет в Республике Сенегал, г. Дакар (2019г.)",
      "Участница Международного хореографического конкурса им. Брегвадзе, г. Санкт-Петербург (2022)",
      "Алина Мунировна является педагогом-репетитором, хореографом и постановщиком современных и эстрадных танцевальных направлений. Имеет опыт работы 5 лет с детьми в возрасте от 2,5 до 15 лет.",
      "Работает на результат, уделяя внимание на каждого своего ученика. Под её руководством дети становились лауреатами I, ll, lll степеней, а также лауреатами Гран-При на международных и всероссийских конкурсах таких, как: «Живи танцуя», «Lime Fest» «Кубок Поволжья», «Морозко», «Твой выход» и другие.",
    ],
  },
  {
    key: 3,
    src: `${comanda7}`,
    webp: `${comanda7webp}`,
    avif: `${comanda7avif}`,
    name: "Тикунова Екатерина",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "Тикунова Екатерина Сергеевна- педагог хореограф, руководитель хореографического коллектива.",
      "Окончила Пермский Краевой Колледж Искусств и Культуры, кафедру хореографии с красным дипломом.",
      "Так же Екатерина Сергеевна в 2024 году закончила Казанский Государственный Институт Культуры, факультет высшая школа искусств, кафедра хореографического искусства.",
      "С самого детства была погружена в мир искусства, с 3 лет занималась в хореографическом коллективе «Радуга». Была участницей народного ансамбля песни и танца «Маков цвет».",
      "За всю творческую деятельность была награждена дипломами лауреата 1,2,3 степеней районных, региональных, всероссийский и международных конкурсов, как в качестве участника, так и в роли педагога коллектива.",
      "Имеет педагогический опыт 5 лет. Работала с разными возрастными группами от трех лет и с детьми более старшего возраста.",
      "Своей задачей считает прививать детям любовь к танцу, к искусству хореографии, раскрывая при этом потенциал каждого ребёнка.",
    ],
  },
  {
    key: 4,
    src: `${comanda2}`,
    webp: `${comanda2webp}`,
    avif: `${comanda2avif}`,
    name: "Эльвира Хайдаровна",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "Имеет высшее профессиональное образование Казанского Государственного Университета Культуры и Искусств факультет — Народное Художественное Творчество. Кафедра — современная хореография (красный диплом).",
      "Танцевальный опыт с 1998 года. Преподавательский опыт — 6 лет.",
      "Лауреат и дипломант различных конкурсов и фестивалей, среди которых: Студенческая весна Казань, Созвездие Казань, Новая лиса Саранск, Весенние выкрутасы (в составе студенческого театра танца), Мосты Казань («Трое в машине не считая Эльвиры»), X-Fit style baby cup Москва (трио «Кувырком»), Траектория kids Казань, Золотой башмачок Казань (хореографический коллектив «Персонажи»).",
      "Училась у известных российских и зарубежных хореографов.",
      "Неоднократно проводила мастер-классы в рамках фестивалей по современной хореографии.",
    ],
  },
  {
    key: 5,
    src: `${comanda3}`,
    webp: `${comanda3webp}`,
    avif: `${comanda3avif}`,
    name: "Илларионова Зарина",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "Закончила Казанский Государственный Институт Культуры и искусств, факультет высшая школа искусств, кафедра хореографического искусства по профилю подготовки руководитель хореографического коллектива, педагог ( красный диплом).",
      "Танцевальный путь начался в 5 лет в художественной самодеятельности. Далее занималась в школе искусств и становилась победителем на различных Всероссийских и Международных конкурсах( в составе коллектива) . Со временем начала заниматься и развиваться в современных направлениях, таких как контемпорари, джаз-модерн и т.д.",
      "Профессиональная деятельность началась с института в 2018 году , где я Зарина Сергеевна получила огромный опыт на конкурсах , фестивалях , проектах в множестве городов России. Проявляла себя как организатор , исполнитель и постановщик.",
      "Педагогический опыт составляет -5 лет, работает с детками от 3х до 17лет. Имеет свою авторскую методику, ищет подход к каждому ребенку.",
      "Готова делится своим опытом и знаниями о современном танце с детьми , прививать любовь к творчеству и танцам.",
    ],
  },
  {
    key: 6,
    src: `${comanda4}`,
    webp: `${comanda4webp}`,
    avif: `${comanda4avif}`,
    name: "Анастасия Александровна",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "В 2012 году с отличием окончила Детскую Школу Искусств по классу хореографии. После окончания общеобразовательной школы поступила в Республиканское Училище Культуры города Елабуга, по специальности хореографическое творчество. После его окончания Поступила в Институт Культуры города Казани, по специальности хореограф-руководитель коллектива. Имеет бесценный опыт в работе с детьми от 3 лет и старше. Работала в Детской школе искусств на должности хореографа. Дети под началом Анастасии Александровны становились Лауреатами различных степеней на международных и всероссийских конкурсах!",
      "В Театре Танца «Переворот» Анастасия работает с 2017 года. Она преподает различные танцевальные стили, такие как: джаз, модерн, самба, бальный танец, народный танец, jazz modern, хип-хоп, эстрадный и детский танец, так же является постоянной участницей различных мастер классов, семинаров, которые способствуют повышению эффективности работы коллектива и творческому росту самих руководителей.",
    ],
  },
  {
    key: 7,
    src: `${comanda5}`,
    webp: `${comanda5webp}`,
    avif: `${comanda5avif}`,
    name: "Эльвина Альбертовна",
    work: "Профессиональный педагог-хореограф (постановщик)",
    text: [
      "Свою танцевальную деятельность начала в детской школе искусств, закончив ее на отлично.",
      "Далее, поступила в Ульяновский колледж культуры и искусства, на отделение хореографии, где все 4 года являлась солисткой ансамбля танца «Волжанка» при колледже. За период обучения получала множество лауреатств 1,2,3 степеней в Международных, Всероссийских, областных и городских конкурсах. Закончила колледж на красный диплом с отличием.",
      "Параллельно совмещала учебу с работой и имеет опыт работы по специальности — 4 года. Затем, поступила в Казанский Государственный институт культуры, на отделение народной художественной культуры, специализация — руководитель хореографического коллектива.",
      "Владеет методикой преподавания классического танца, народного танца, современного и эстрадного танца, историко-бытового танца, методикой работы с творческим коллективом. Предрасположена к постоянному творчеству, саморазвитию. Любит придумывать новое, чтоб на занятиях было интересно и педагогу, и ученикам. За разноплановые современные подходы к обучению и развитию учеников.",
    ],
  },
];
